import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    NumberField,
    TextField,
    EditButton,
    ShowButton,
    Edit,
    Button,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ReferenceInput,
    SimpleShowLayout,
    useRecordContext,
} from 'react-admin';
import { useWatch } from 'react-hook-form';
import { FileCopy } from '@mui/icons-material';

import { Link } from 'react-router-dom';

import AutocompleteInput from './components/BaseAutocompleteInput';

const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
    <ReferenceInput source="programKey" reference="robot_program_keys" alwaysOn>
        <AutocompleteInput
            optionText="programKey"
            filterToQuery={searchText => ({ partialProgramKey: searchText })}
            shouldRenderSuggestions={searchText => isSearchEnabled(searchText)}
        />
    </ReferenceInput>,
    <ReferenceInput source="taskKey" reference="robot_task_keys" alwaysOn >
        <AutocompleteInput optionText="taskKey" filterToQuery={searchText => ({ partialTaskKey: searchText })} 
            shouldRenderSuggestions={searchText => isSearchEnabled(searchText)} />
    </ReferenceInput>,
];

const toRulePrefix = (values) => {
    const robotInfo = values.configuration ? `R${values.configuration} - ` : '';
    const programInfo = values.programKey ? `${values.programKey} - ` : '';
    const taskInfo = values.taskKey ? `${values.taskKey}` : '';
    return `${robotInfo}${programInfo}${taskInfo}`;
};


const RuleNameCreateInput = () => {
    const values = useWatch();
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            Rule name:&nbsp;&nbsp;&nbsp;<TextInput source="ruleNamePrefix" defaultValue={toRulePrefix(values)} label={false} disabled fullWidth/>&nbsp;&nbsp;-&nbsp;&nbsp;
            <TextInput source="ruleNamePostfix" label="Input name postfix" />
        </div>
    );
};

const isSearchEnabled = (searchText) => {
    return searchText.trim().length === 0
        ||searchText.trim() === '*'
        || searchText.trim().length >= 3;
};

const CloneRuleButton = () => {
    const record = useRecordContext();
    return (
            <Button
                color="primary"
                component={Link}
                to={{
                    pathname: '/robot_task_variable_rules/create',
                    state:
                        { record:
                            {
                                configuration: record.configuration,
                                programKey: record.programKey,
                                taskKey: record.taskKey,
                                condition: record.condition,
                                action: record.action,
                                sequenceNumber: record.sequenceNumber,
                                ruleNamePostfix: record.ruleNamePostfix
                            }
                        },
                }}>
                <FileCopy />&nbsp;Clone
            </Button>
        );
    }

export const TaskVariableRuleList = props => {

    return (
        <List {...props} syncWithLocation filters={filters} sort={{field:'', order:''}}
            filterDefaultValues={{ configuration: '*' }} pagination={false}>
            <Datagrid rowClick="expand" expand={<TaskVariableRuleShowSummary />} bulkActionButtons={false}>
                <TextField source="configuration" />
                <TextField source="programKey" style={{ wordBreak: 'break-word' }} />
                <TextField source="taskKey" style={{ wordBreak: 'break-word' }} />
                <TextField source="ruleNamePostfix" style={{ wordBreak: 'break-word' }} />
                <NumberField source="sequenceNumber" label="Execution Sequence" />
                <ShowButton />
                <EditButton />
                <CloneRuleButton />
            </Datagrid>
        </List>
    );
}

export const TaskVariableRuleCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="configuration" />
            <ReferenceInput source="programKey" reference="robot_program_keys" >
                <AutocompleteInput optionText="programKey"
                    filterToQuery={searchText => ({ partialProgramKey: searchText })} 
                    fullWidth
                    shouldRenderSuggestions={searchText => isSearchEnabled(searchText)} 
                    />
            </ReferenceInput>
            <ReferenceInput source="taskKey" reference="robot_task_keys" >
                <AutocompleteInput optionText="taskKey"
                    filterToQuery={searchText => ({ partialTaskKey: searchText })} 
                    fullWidth
                    shouldRenderSuggestions={searchText => isSearchEnabled(searchText)} />
            </ReferenceInput>
            <TextInput source="condition" fullWidth multiline />
            <TextInput source="action" fullWidth multiline />
            <NumberInput source="sequenceNumber" defaultValue={100} />
            <RuleNameCreateInput />
        </SimpleForm>
    </Create>
);

export const TaskVariableRuleEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="ruleId" />
            <TextField source="configuration" />
            <ReferenceInput source="programKey" reference="robot_program_keys" 
                >
                <AutocompleteInput optionText="programKey"
                    filterToQuery={searchText => ({ partialProgramKey: searchText })} 
                    fullWidth
                    shouldRenderSuggestions={searchText => isSearchEnabled(searchText)} 
                    />
            </ReferenceInput>
            <ReferenceInput source="taskKey" reference="robot_task_keys" >
                <AutocompleteInput optionText="taskKey"
                    filterToQuery={searchText => ({ partialTaskKey: searchText })} 
                    fullWidth
                    shouldRenderSuggestions={searchText => isSearchEnabled(searchText)} />
            </ReferenceInput>
            <TextInput source="condition" fullWidth multiline />
            <TextInput source="action" fullWidth multiline className="code-input" />
            <NumberInput source="sequenceNumber" />
            <RuleNameCreateInput />
        </SimpleForm>
    </Edit>
);

function CodeField({ source }) {
    const record = useRecordContext();
    return <pre>{record[source]}</pre>;
}

export const TaskVariableRuleShowSummary = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="condition" fullWidth />
                <CodeField source="action" fullWidth />
            </SimpleShowLayout>
        </Show>
    );
};

export const TaskVariableRuleShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="ruleId" />
                <TextField source="configuration" />
                <TextField source="programKey" />
                <TextField source="taskKey" />
                <TextField source="condition" fullWidth  />
                <CodeField source="action" fullWidth />
                <NumberField source="sequenceNumber" />
                <TextField source="ruleName" />
            </SimpleShowLayout>
        </Show>
    );
};