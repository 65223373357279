import React from "react";
import {
    TextInput,
    RadioButtonGroupInput,
    ImageInput,
    ImageField
} from 'react-admin';


export const ImageUpload = ({ uploadMode, setUploadMode, imageUploaded, setImageUploaded, imageSource, imageFile, primaryImageUrl, primaryImageThumbnailUrl, ...props }) => {
    return (<>
        <RadioButtonGroupInput {...props} source={imageSource} defaultValue="upload" choices={[
            { id: 'upload', name: 'Upload' },
            { id: 'url', name: 'URL' },
        ]}
            onChange={() => setUploadMode(!uploadMode)}
        />
        {uploadMode &&
            (<ImageInput {...props} source={imageFile} label="Upload primary image" 
                onChange={(image) => setImageUploaded(image != null)}  >
                <ImageField source="src" title="title" />
            </ImageInput>)}
        <br />
        {(!uploadMode && imageUploaded) && <><span style={{ "color": "red" }}>
            Image was uploaded. Remove the uploaded image if you'd like to enter a URL instead.</span><br /></>}
        {!uploadMode && <><TextInput {...props} source={primaryImageUrl} label="Set primary image URL" /><br />
            <TextInput {...props} source={primaryImageThumbnailUrl} label="Set primary image thumbnail URL" /></>}
    </>);
};