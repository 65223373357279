import React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    ShowButton,
    Show,
    SimpleShowLayout,
    Edit,
    Create,
    SimpleForm,
    NumberInput,
    NumberField,
    BooleanField,
    BooleanInput,
    FunctionField,
    Labeled,
} from 'react-admin';
import { JsonField, JsonInput } from "react-admin-json-view";
import { BluehillPagination } from './bluehillPagination';

const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
];
const defaultJointState = {
    "stampSec": 0,
    "stampNsec": 0,
    "jointAngles": [0, 0, 0, 0, 0, 0]
};

const isNumberOrNumberArray = value => {
    if (typeof value === 'number' && !isNaN(value)) {
        return true;
    }
    if (Array.isArray(value)) {
        return value.every(item => typeof item === 'number' && !isNaN(item));
    }
    return false;
};
const validJointState = (value, all, props) => {
    if (typeof value !== 'object' || value === null) {
        return 'Invalid JSON object';
    }
    return Object.values(value).every(isNumberOrNumberArray) ? undefined
        : 'Ensure all values are type int or float';
}

export const KeypointList = props => (
    <List {...props} syncWithLocation sort={{ field: '', order: '' }} filters={filters}
        filterDefaultValues={{ configuration: 0 }} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="configuration" />
            <TextField label="ID" source="id" />
            <TextField source="name" />
            <BooleanField source="calibrated" />
            <FunctionField label="Joint State" render={record => JSON.stringify(record.jointState)} />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export const KeypointShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField source="configuration" />
            <NumberField label="ID" source="id" />
            <TextField source="name" />
            <BooleanField source="calibrated" />
            <JsonField source="jointState" />
        </SimpleShowLayout>
    </Show>
);

export const KeypointEdit = props => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <Labeled label="Configuration" >
                    <NumberField source="configuration" />
                </Labeled>
                <Labeled label="ID" >
                    <NumberField label="ID" source="id" />
                </Labeled>
                <TextInput source="name" />
                <BooleanInput source="calibrated" />
                <JsonInput source="jointState" validate={[validJointState]} />
            </SimpleForm>
        </Edit>
    );
}

export const KeypointCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <NumberInput source="configuration" />
                <TextInput source="name" />
                <BooleanInput source="calibrated" />
                <JsonInput source="jointState" defaultValue={defaultJointState} 
                    validate={[validJointState]} />
            </SimpleForm>
        </Create>
    );
}