import React from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    Edit,
    DeleteButton,
    Create,
    SimpleForm,
} from 'react-admin';

export const RobotIOGroupList = props => {
    return(
    <List {...props} pagination={false}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="groupId" label="Group Id"/>
            <TextField source="groupName" label="Group Name"/>
            <TextField source="sequenceNumber" label="Sequence Number"/>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
)};

export const RobotIOGroupEdit = props => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="groupName" label="Group Name"/>
                <TextInput source="sequenceNumber" label="Sequence Number"/>
            </SimpleForm>
        </Edit>
    );
}

export const RobotIOGroupCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <TextInput source="groupName" label="Group Name"/>
            <TextInput source="sequenceNumber" label="Sequence Number"/>
        </SimpleForm>
    </Create>
);
