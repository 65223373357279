import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Show,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    Edit,
    Create,
    DeleteButton,
    SimpleForm,
    BooleanInput,
    TextInput,
    SelectInput,
    NumberField,
    SimpleShowLayout,
    ArrayInput,
    SimpleFormIterator,
    useRecordContext,
    ArrayField,
    useSimpleFormIteratorItem,
    NumberInput,
    BooleanField,
    Labeled,
    FunctionField,
    ReferenceInput,
    useDataProvider,
    AutocompleteInput,
    useNotify,
} from 'react-admin';
import { Divider, Grid } from '@mui/material';
import { useWatch, useFormContext } from 'react-hook-form';

const filters = [
    <TextInput label="Robot ID" source="robotId" alwaysOn />,
];
const ioControlTypeFields = {
    "Bool": ["ioPin", "onVal", "offVal", "reverseVal"],
    "BoolReverse": ["ioPin", "onVal", "offVal"],
    "Point": ["ioPin", "onMsg", "offMsg"],
    "PointReverse": ["ioPin", "onMsg", "offMsg"],
    "Weight": [],
    "Timed": ["ioPin", "onVal", "offVal", "timeTolerance"],
    "Passthrough": []
}
const basicTypes = [
    "Bool", "Point", "Passthrough"
]
const advancedTypes = {
    "Bool": ["BoolReverse", "Weight", "Timed"],
    "Point": ["PointReverse", "Weight", "Timed"],
    "Passthrough": []
}
const topicsByType = {
    "Bool": ["arduino_controller", "arduino_syrup"],
    "BoolReverse": ["arduino_controller", "arduino_syrup"],
    "Point": ["arduino_controller", "grinder_servo", "/cam0/arducam/camera/flash"],
    "PointReverse": ["arduino_controller",],
    "Weight": ["arduino_weight", "arduino_weight_syrup", "arduino_weight_ice"],
    "Timed": ["arduino_gs3_timed",],
    "Passthrough": []
}
const getIoControlTypeChoices = (index, selectedBasicType) => {
    if (index === 0) {
        return basicTypes.map(type => ({ id: type, name: type }))
    } else {
        if (!selectedBasicType || !(selectedBasicType in advancedTypes)) {
            return [];
        }
        return advancedTypes[selectedBasicType].map(type => {
            if (type === "BoolReverse") {
                return { id: type, name: "Reverse" }
            } else if (type === "PointReverse") {
                return { id: type, name: "Reverse" }
            }
            return { id: type, name: type }
        });
    }
}
const getIoControlTopicChoices = (selectedType) => {
    return topicsByType[selectedType]?.map(topic => ({ id: topic, name: topic }));
}

const IoControlInput = (props) => {
    const { index, total } = useSimpleFormIteratorItem();
    const [selectedType, setSelectedType] = useState("");
    const handleTypeChange = (index, value) => {
        setSelectedType(value);
    };
    const { getValues } = useFormContext();
    const record = getValues();
    useEffect(() => {
        // Set selectedType once ioControls[index].type is loaded
        const ioControlType = record?.ioControls[index]?.type;
        if (ioControlType) {
            handleTypeChange(index, ioControlType);
        }
    }, [record, index]);
    const ioPinRequired = ['Bool', 'BoolReverse', 'Point', 'PointReverse'].includes(selectedType);
    const onValAndOffValRequired = ['Bool', 'BoolReverse'].includes(selectedType);
    return (<>
        <SelectInput
            source={`ioControls[${index}].type`}
            label="Type"
            choices={getIoControlTypeChoices(index, record?.ioControls[0].type) ?? []}
            onChange={(event) => handleTypeChange(index, event.target.value)}
            required
            disabled={index == 0 && total > 1}
        />
        {(selectedType != "" && selectedType != "Passthrough") && <SelectInput source={`ioControls[${index}].topic`}
            label="Topic"
            choices={getIoControlTopicChoices(selectedType) ?? []}
            defaultValue={index != 0 ? record?.ioControls[0].topic : ""}
            required
        />}
        {ioControlTypeFields[selectedType]?.includes("ioPin") &&
            <NumberInput source={`ioControls[${index}].ioPin`} label="IO Pin" required={ioPinRequired} />}
        {ioControlTypeFields[selectedType]?.includes("onVal") &&
            <NumberInput source={`ioControls[${index}].onVal`} label="On Val" required={onValAndOffValRequired} />}
        {ioControlTypeFields[selectedType]?.includes("offVal") &&
            <NumberInput source={`ioControls[${index}].offVal`} label="Off Val" required={onValAndOffValRequired} />}
        {ioControlTypeFields[selectedType]?.includes("reverseVal") &&
            <NumberInput source={`ioControls[${index}].reverseVal`} label="Reverse Val" />}
        {ioControlTypeFields[selectedType]?.includes("onMsg") &&
            <div style={{ display: 'flex' }}>
                <NumberInput source={`ioControls[${index}].onMsg.x`} label="On Msg X" required />
                <NumberInput source={`ioControls[${index}].onMsg.y`} label="On Msg Y" required />
                <NumberInput source={`ioControls[${index}].onMsg.z`} label="On Msg Z" required />
            </div>}
        {ioControlTypeFields[selectedType]?.includes("offMsg") &&
            <div style={{ display: 'flex' }}>
                <NumberInput source={`ioControls[${index}].offMsg.x`} label="Off Msg X" required />
                <NumberInput source={`ioControls[${index}].offMsg.y`} label="Off Msg Y" required />
                <NumberInput source={`ioControls[${index}].offMsg.z`} label="Off Msg Z" required />
            </div>}
        {ioControlTypeFields[selectedType]?.includes("timeTolerance") &&
            <NumberInput source={`ioControls[${index}].timeTolerance`} label="Time Tolerance" />}
        {(selectedType != "" && selectedType != "Passthrough") &&
            <><BooleanInput source={`ioControls[${index}].cancelOnPause`} label="Cancel on Pause" />
                <BooleanInput source={`ioControls[${index}].cancelOnStop`} label="Cancel on Stop" />
                <TextInput source={`ioControls[${index}].legacyId`} label="Legacy ID" /></>}
    </>);
}


export const RobotIOControlDataList = props => (
    <List {...props} sort={{ field: 'ioControlKey', order: 'ASC' }}
        filters={filters} filterDefaultValues={{ robotId: 0 }} pagination={false}>
        <Datagrid>
            <TextField source="robotId" />
            <TextField source="ioControlKey" />
            <TextField source="name" />
            <ArrayField source="ioControls">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="type" label="Type" />
                    <TextField source="topic" label="Topic" />
                    <TextField source="ioPin" label="IO Pin" />
                    <NumberField source="legacyId" label="Legacy ID" />
                </Datagrid>
            </ArrayField>
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const RobotIOControlDataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="robotId" />
            <TextField source="ioControlKey" />
            <TextField source="name" />
            <Divider>IO Controls</Divider>
            <ArrayField source="ioControls">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="type" label="Type" />
                    <TextField source="topic" label="Topic" />
                    <TextField source="ioPin" label="IO Pin" />
                    <NumberField source="legacyId" label="Legacy ID" />
                    <NumberField source="onVal" label="On Value" />
                    <NumberField source="offVal" label="Off Value" />
                    <FunctionField source="onMsg" label="On Msg" render={record => {
                        if (!record.onMsg) {
                            return null;
                        }
                        return `x: ${record.onMsg?.x ?? 'null'}, 
                        y: ${record.onMsg?.y ?? 'null'}, 
                        z: ${record.onMsg?.z ?? 'null'}`;
                    }} />
                    <FunctionField source="offMsg" label="Off Msg" render={record => {
                        if (!record.offMsg) {
                            return null;
                        }
                        return `x: ${record.offMsg?.x ?? 'null'}, 
                        y: ${record.offMsg?.y ?? 'null'}, 
                        z: ${record.offMsg?.z ?? 'null'}`;
                    }} />
                    <NumberField source="reverseVal" label="Reverse Value" />
                    <NumberField source="timeTolerance" label="Time Tolerance" />
                    <BooleanField source="cancelOnPause" label="Cancel on Pause" />
                    <BooleanField source="cancelOnStop" label="Cancel on Stop" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

const IOControlDataContext = React.createContext([]);

function IOControlsInput({ useCommonData }) {
    const [choices, setChoices] = useState([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const record = useRecordContext();
    useEffect(() => {
        const resource = useCommonData ? `robot_common_ios_control_data` : `robot_ios_control_data`;
        const params = useCommonData || record?.robotId === undefined ? {} : { filter: { robotId: record.robotId } };
        dataProvider.getList(resource, params)
            .then(({ data }) => {
                setChoices(data.sort((a, b) => a.ioControlKey.localeCompare(b.ioControlKey)));
            })
            .catch((error) => {
                notify(error.message);
            });
    }, [dataProvider]);

    return (
        <>
            <AutocompleteInput source="ioControlKey" optionText="ioControlKey" optionValue="ioControlKey" choices={choices} />
            <IOControlDataContext.Provider value={choices}>
                <IOControlDataFields />
            </IOControlDataContext.Provider>
        </>
    );
}

function IOControlDataFields() {
    const ioControlKey = useWatch({ name: 'ioControlKey' });
    const { setValue } = useFormContext();
    const ioControlKeyOptions = React.useContext(IOControlDataContext);
    useEffect(() => {
        const ioControlKeyOption = ioControlKeyOptions.find(option => option.ioControlKey === ioControlKey);
        if (ioControlKeyOption == null) {
            return;
        }
        setValue('name', ioControlKeyOption.name);
        setValue('ioControls', ioControlKeyOption.ioControls);
    }, [ioControlKeyOptions, ioControlKey]);
    return ioControlKey ? (
        <>
            <TextInput source="name" required />
            <Divider>IO Controls</Divider>
            <ArrayInput source="ioControls" fullWidth>
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }} reOrderButtons={<></>} fullWidth inline>
                    <IoControlInput />
                </SimpleFormIterator>
            </ArrayInput>
        </>
    ) : null;
}

export const RobotIOControlDataCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput source="robotId" reference="robots" required>
                    <AutocompleteInput source="robotId" optionText="robotId" />
                </ReferenceInput>
                <IOControlsInput useCommonData={true} />
            </SimpleForm>
        </Create>
    );
};

export const RobotIOControlDataEdit = props => {
    return (<Edit {...props}>
        <SimpleForm>
            <Grid>
                <Labeled label="Robot ID">
                    <TextField source="robotId" />
                </Labeled>
            </Grid>
            <Grid>
                <Labeled label="IO control key">
                    <TextField source="ioControlKey" required />
                </Labeled>
            </Grid>
            <IOControlsInput useCommonData={false} />
        </SimpleForm>
    </Edit>);
};