import * as React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    EditButton,
    ShowButton,
    Show,
    SimpleShowLayout,
    Edit,
    DeleteButton,
    Create,
    SimpleForm,
    BooleanInput,
    ArrayField,
    ReferenceField,
    Labeled,
    required,
} from 'react-admin';
import { ExpandableContent } from "./components/ExpandableContent";

export const AttributeMetadataList = props => (
    <List {...props} pagination={false} >
        <Datagrid bulkActionButtons={false}>
            <TextField label="Attribute Id" source="attributeId" />
            <TextField label="Attribute Name" source="attributeName" />
            <BooleanField label="Displayed for pickup" source="displayedForPickup" />
            <TextField label="Attribute Key" source="attributeKey" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const AttributeMetadataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="attributeId" />
            <TextField source="attributeName" />
            <BooleanField source="displayedForPickup" />
            <TextField source="attributeKey" />
            <ArrayField source="attributeOptions">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="optionId" />
                    <ReferenceField label="Option name" source="optionId" reference="product_options_metadata" >
                        <TextField source="optionName" />
                    </ReferenceField>
                    <TextField source="optionKey" />
                    <TextField source="shortDescription" />
                    <BooleanField source="defaultOption" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);

export const AttributeMetadataCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="attributeName" validate={[required()]} />
            <BooleanInput source="displayedForPickup" />
            <ExpandableContent buttonText="Developer Use Only">
                <TextInput source="attributeKey" fullWidth />
            </ExpandableContent>
        </SimpleForm>
    </Create>
);

export const AttributeMetadataEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Labeled label="Attribute">
                <TextField source="attributeId" />
            </Labeled>
            <TextInput source="attributeName" />
            <BooleanInput source="displayedForPickup" />
            <Labeled label="Attribute Key">
                <TextField source="attributeKey" />
            </Labeled>
            <Labeled label="Attribute Options">
                <ArrayField source="attributeOptions">
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="optionId" />
                        <ReferenceField label="Option name" source="optionId" reference="product_options_metadata" >
                            <TextField source="optionName" />
                        </ReferenceField>
                        <TextField source="optionKey" />
                        <TextField source="shortDescription" />
                        <BooleanField source="defaultOption" />
                    </Datagrid>
                </ArrayField>
            </Labeled>
        </SimpleForm>
    </Edit>
);