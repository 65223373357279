import React, { cloneElement, useEffect, useState } from "react";
import arrayMove from "array-move";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, useDataProvider, useArrayInput } from "react-admin";
import { useWatch } from 'react-hook-form';
import * as diff from "fast-array-diff";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/AddCircleOutline';
import UndoIcon from '@mui/icons-material/Undo';
import CloseIcon from '@mui/icons-material/RemoveCircleOutline';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    padding: 10,
    display: 'flex',
    backgroundColor: '#A9A9A9'
  },
  h6: {
    width: '96%',
    color: "white"
  },
  button: {
    color: 'white',
  },
  item: {
    marginTop: 12,
  }
});

const iconMap = {
  'Remove': <CloseIcon />,
  'Add': <AddIcon />,
  'Undo': <UndoIcon />,
}

const CustomButton = ({ label, ...rest }) => {
  return (
    <Button label={label} {...rest} >
      {iconMap[label]}
    </Button>
  );
}

const RemovedItem = ({ ele, onClick, reference, filterObj, oldPos }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const getChoices = async () => {
      try {
          const { data, } = await dataProvider.getList(reference, {
              pagination: {},
              sort: {},
              filter: {
                ...filterObj,
                ids: ele || '',
              },
          });
          if (data) {
            setChoices(data)
          }
      } catch (error) {
          console.log('error', error);
      }
    }
    getChoices();
  }, []);

  const renderText = (ele) => {
    const result = choices.find(e => ele === e?.id)
    return result ? `${oldPos}:${result?.id}: ${result?.name}` : '';
  }


  return (
    <div className={classes.root}>
      <Typography label="Name" className={classes.h6} >
        (REMOVED) {renderText(ele)}
      </Typography>
    </div>
  );
}

const SortableItem = ({
  droppableId, onInsertItem, item, index, baseSource, member, onRemove, onRecover, diffActions, children, filterObj, resource, ...props }) => {
  const bottomAction = diffActions.filter(e => e.type === 'remove').find(e => (index === 0 && e.newPos === 1) || (index !== 0 && e.newPos === index + 1));
  const topAction = diffActions.filter(e => e.type === 'remove').find(e => (index === 0 && e.newPos === 0));
  const shouldHighlight = () => {

    return diffActions.filter(e => e.type === 'add').find(e => (e.newPos === index && e.items?.find(ele => ele === item)) 
      || e.items?.find((ele, i) => ele === item && (e.newPos + i) === index));
  }
  return (
    <>
      {topAction ? topAction?.items.map((ele, i) => (
        <RemovedItem key={`${ele}-${i}`} ele={ele} oldPos={topAction?.oldPos + i} onClick={() => onRecover(index, ele)} reference={children?.props?.reference} filterObj={filterObj} />
      )) : null}
      <Draggable draggableId={droppableId} index={index}>
        {(provided, snapshot) => {
          const style = {
            ...provided.draggableProps.style,
            backgroundColor: shouldHighlight() ? 'rgba(46,160,67,0.15)' : 'white',
            marginTop: 5,
          };
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
            >
              <div style={{ display: "flex", alignItems: 'center' }}>
                <div style={{ padding: 10 }}>
                  <Typography variant="subtitle1">{index}</Typography>
                </div>
                <div style={{ flexGrow: 1 }}>
                  {cloneElement(children, {
                    resource: resource,
                    ...children.props,
                    source: member,
                  })}
                </div>
                <CustomButton onClick={onRemove} label='Remove' />
              </div>
              {!snapshot?.isDragging ? <CustomButton onClick={onInsertItem} label='Add' /> : null}
            </div>
          )}
        }
      </Draggable>
      {bottomAction && bottomAction.newPos > 0 ? bottomAction?.items.map((ele, i) => (
        <RemovedItem key={`${ele}-${i}`} ele={ele} oldPos={bottomAction?.oldPos + i} onClick={() => onRecover(index + 1, ele)} reference={children?.props?.reference} filterObj={filterObj}/>
        ))
        : null}
    </>
  );
}

const SortableList = (props) => {

  const [initItems, setInitItems] = useState([]);

  useEffect(() => {
    if (!initItems?.length && items?.length) {
      setInitItems([...items]);
    }
  }, []);

  const {
    children,
    className,
    resource,
    source,
    disabled,
    fields,
    disableClear,
    disableReordering,
    inline,
    fullWidth,
    onInsertItem,
    items,
    onRemoveItem,
    baseSource,
    onRecover,
    ...rest
  } = props;
  return fields ? fields?.map((member, index) => (
      <SortableItem
        key={`item-${member?.id}`}
        baseSource={baseSource}
        index={index}
        {...rest}
        item={items[index]}
        diffActions={diff.getPatch(initItems, items)}
        onRemove={() => onRemoveItem(index)}
        onRecover={onRecover}
        fields={fields}
        onInsertItem={() => onInsertItem(index)}
        member={`${source}.${index}`}
        resource={resource}
        source={source}
        inline={inline}
        droppableId={`item-${member?.id}`}
      >
        {children}
      </SortableItem>
    ))
    : null;
};


const SortableIterator = (props) => {
  const { source, children, } = props;

  const items = useWatch({ name: source });

  const { fields, replace } = useArrayInput(props);

  const onDragEnd = (result) => {

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    replace(arrayMove(items, result.source.index, result.destination.index));

  }

  const onRemoveItem = (index) => {

    const baseItems = items || [];
    baseItems.splice(index, 1);

    replace([...baseItems]);
  };


  const onAddEmpty = () => {
    const baseItems = items || [];

    if (fields?.length) {
      replace([null, ...baseItems]);
    } else {
      baseItems.splice(0, 0, null)
      replace([...baseItems]);
    }
  };

  const onInsertItem = (index) => {
    const baseItems = items || [];

    baseItems.splice((index + 1), 0, null);

    replace([...baseItems]);

  };

  const onRecover= (index, item) => {
    const baseItems = items || [];

    baseItems.splice(index, 0, item);
    replace([...baseItems]);
  };
  const classes = useStyles();
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => {
          return (
            <>
              <Typography variant="body1" className={classes.item}>
                <CustomButton onClick={onAddEmpty} label='Add' />
              </Typography>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <SortableList
                  {...props}
                  onInsertItem={onInsertItem}
                  items={items}
                  fields={fields}
                  baseSource={source}
                  onRecover={onRecover}
                  onRemoveItem={onRemoveItem}
                  helperClass="sortableHelper"
                >
                  {children}
                  </SortableList>
                {provided.placeholder}
              </div>

            </>
          )}
        }
      </Droppable>
    </DragDropContext>
  );
}

export default SortableIterator;