import React from 'react';

export default function AuthImage({ url, ...props }) {
    const [imageData, setImageData] = React.useState(null);
    React.useEffect(() => {
        if (!url) {
            return;
        }
        fetch(`${url}?isFromReactAdmin=true`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.blob())
            .then(blob => {
                setImageData(URL.createObjectURL(blob));
            });
    }, [url]);

    return imageData ? <img alt="" {...props} src={imageData} /> : null;
}
