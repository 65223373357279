import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    NumberField,
    TextField,
    DateField,
    ShowButton,
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput,
    NumberInput,
    SimpleShowLayout,
} from 'react-admin';
import { JsonField } from "react-admin-json-view";
import { BluehillPagination } from './bluehillPagination';


const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
    <TextInput label="Action name" source="partialName" alwaysOn />,
    <BooleanInput label="New Action API" source="useConfigurationQuery" alwaysOn />,
];

export const ActionList = props => (
    <List {...props} perPage={50} sort={{field:'', order:''}}
        filters={filters} filterDefaultValues={{ configuration:0, useConfigurationQuery: true }}
        pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <NumberField source="configuration" />
            <TextField source="name" style={{ wordBreak: 'break-word' }} />
            <ShowButton />
        </Datagrid>
    </List>
);

export const ActionCreate = props => (
    <Edit {...props}>
        <SimpleForm>
            <NumberInput source="configuration" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const ActionEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <NumberField source="id" />
            <NumberField source="configuration" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const ActionShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField source="id" />
            <NumberField source="configuration" />
            <TextField source="name" />
            <NumberField source="type" />
            <JsonField
                source="properties"
                reactJsonOptions={{
                  // Props passed to react-json-view
                  name: null,
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                }}
              />
            <JsonField
                source="additionalProperties"
                reactJsonOptions={{
                  // Props passed to react-json-view
                  name: null,
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                }}
              />
            <DateField source="lastModifiedTime" showTime />
        </SimpleShowLayout>
    </Show>
);