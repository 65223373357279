import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-admin';
import { Popover, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

import { getRefreshInterval, setRefreshInterval } from '../bluehillAuthDao';

export function AutoRefreshControl() {
    const initialRefreshInterval = Number.parseInt(getRefreshInterval(), 10);
    const [countdown, setCountdown] = useState(0);
    const [refreshInterval, setCurrentRefreshInterval] = useState(Number.isNaN(initialRefreshInterval) ? 0 : initialRefreshInterval);
    const [refreshTimer, setRefreshTimer] = useState(null);
    const [anchor, setAnchor] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [editingInterval, setEditingInterval] = useState(refreshInterval);

    function onOpenIntervalForm(ev) {
        setAnchor(ev.currentTarget);
        setFormOpen(true);
    }

    const timerCallback = useCallback(() => {
        setCountdown(c => c - 1000);
    }, []);

    function onUpdateInterval() {
        setCurrentRefreshInterval(editingInterval);
        setRefreshInterval(editingInterval);
        setFormOpen(false);
    }

    useEffect(() => {
        if (refreshTimer && countdown < 0) {
            refreshTimer && clearInterval(refreshTimer);
            window.location.reload();
        }
    }, [countdown, refreshTimer]);

    useEffect(() => {
        if (refreshInterval) {
            if (!refreshTimer) {
                console.log('start timer');
                const timer = setInterval(timerCallback, 1000);
                setCountdown(refreshInterval);
                setRefreshTimer(timer);
            }
        } else {
            if (refreshTimer) {
                console.log('stop timer');
                clearInterval(refreshTimer);
                setRefreshTimer(null);
            }
        }
        return () => {
            if (refreshTimer) {
                console.log('stop timer');
                clearInterval(refreshTimer);
                setRefreshTimer(null);
            }
        };
    }, [refreshInterval, refreshTimer, timerCallback]);

    return (
        <>
            <Button label="Refresh setting" onClick={onOpenIntervalForm}>
                <HourglassEmptyIcon />
            </Button>
            <Popover
                anchorEl={anchor}
                open={formOpen}
                onClose={() => setFormOpen(false)}
            >
                <form
                    onSubmit={(ev) => {
                        ev.preventDefault();
                        onUpdateInterval();
                    }}
                    className="refresh-interval-form"
                >
                    <TextField label="Interval in minutes" variant="filled" value={Math.round(editingInterval / 60000)} onChange={(ev) => {
                        const ms = Number.parseInt(ev.target.value, 10);
                        setEditingInterval(Number.isNaN(ms) ? 0 : ms * 60000);
                    }} />
                    <Button color="primary" label="Update" onClick={() => onUpdateInterval()}>
                        <CheckIcon />
                    </Button>
                </form>
            </Popover>
        </>
    );
}
