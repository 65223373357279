import * as React from "react";
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
    List,
    Datagrid,
    BooleanField,
    TextField,
    ReferenceField,
    DeleteButton,
    BulkUpdateButton,
    BulkUpdateWithConfirmButton,
    Create,
    SimpleForm,
    BooleanInput,
    NullableBooleanInput,
    ReferenceInput,
    SelectInput,
    TextInput,
    NumberInput,
    ReferenceArrayInput,
    required,
    Button,
    FormDataConsumer,
    useDataProvider,
    Labeled,
    FunctionField,
    Show,
    SimpleShowLayout,
    useNotify,
    SingleFieldList,
    ChipField,
    ShowButton, 
    useRecordContext,
    DateField,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Visibility, VisibilityOff, LibraryAdd } from '@mui/icons-material';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { BluehillPagination } from './bluehillPagination';
import { ExpandableContent } from "./components/ExpandableContent";
import { JsonField } from "react-admin-json-view";
import { Link } from 'react-router-dom';
import AutocompleteInput from './components/BaseAutocompleteInput';
import AutocompleteArrayInput from './components/BaseAutocompleteArrayInput';
import DisabledReasonInput from './components/DisabledReasonInput';

const productMetadataRenderer = productMetadata => productMetadata?.productName;
const attributeRenderer = attribute => attribute?.attributeName;
const optionRenderer = (option) => `${option?.attributeName}: ${option?.optionName}`;
const robotRenderer = robot => robot?.configuration;
const reasonRenderer = (reasonStatusInformation) => {
    const reasonTitle = reasonStatusInformation?.reasonTitle;
    const reasonDescription = reasonStatusInformation?.reasonDescription;
    return reasonTitle ? (reasonDescription ? `${reasonTitle}: ${reasonDescription}` : reasonTitle) : '';
}

const DisabledReasonField = ({ label }) => {
    const record = useRecordContext();
    if (!record) return null;
    return <FunctionField label={label}
        render={record => reasonRenderer(record?.resourceStatusInformation)} />;
}

const toAttributeName = (optionName) => {
    if (optionName) {
        return optionName;
    } else {
        return '';
    }
};

const filters = [
    <NumberInput label="Configuration" source="configuration" alwaysOn />,
    <ReferenceInput sort={{ field: '', order: 'ASC' }}
        label="Product" source="productId" reference="product_metadata">
        <SelectInput optionText={productMetadataRenderer} />
    </ReferenceInput>,
    <ReferenceInput sort={{ field: '', order: 'ASC' }}
        label="Attribute" source="attributeId" reference="product_attributes_metadata">
        <SelectInput optionText={attributeRenderer} />
    </ReferenceInput>,
    <NullableBooleanInput label="Is enabled?" source="isEnabled" falseLabel="Disabled" trueLabel="Enabled" nullLabel="All" alwaysOn />,
    <NullableBooleanInput label="Is attribute?" source="isAttribute" nullLabel="All" falseLabel="Product Mapping" trueLabel="Attribute" alwaysOn />,
    <ReferenceArrayInput sort={{ field: 'optionId', order: 'ASC' }}
         source="optionIds" reference="product_options_metadata" style={{ minWidth: '120px' }} alwaysOn 
        >
        <AutocompleteArrayInput label="Option" queryFields={['attributeName', 'optionName']} optionText={optionRenderer} />
    </ReferenceArrayInput>,
];

const BulkActionButton = ({ actionType, data, icon, dialogMessage, linkTo, ...props }) => {
    const [open, setOpen] = useState(false);
    const [reasonType, setReasonType] = useState('OUT_OF_STOCK');
    const [reasonDescription, setReasonDescription] = useState('');

    const handleClick = (event) => {
        // Trigger the BulkUpdateButton action
        if (props.onClick) {
            props.onClick(event);
        }

        // Open the dialog
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {actionType === "Disable" ?
                <BulkUpdateWithConfirmButton
                    {...props}
                    label={"Disable"}
                    data={{ ...data, 
                        resourceStatusInformation: {
                            reasonType: reasonType,
                            reasonDescription: reasonDescription
                        }
                    }}
                    icon={icon}
                    onClick={handleClick}
                    confirmContent={
                        <DisabledReasonInput
                            reasonType={reasonType}
                            setReasonType={setReasonType}
                            reasonDescription={reasonDescription}
                            setReasonDescription={setReasonDescription}
                        />
                    }
                />
                : <BulkUpdateButton {...props} label={"Enable"} data={data} icon={icon} onClick={handleClick} />
            }

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Action Needed</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" component={Link} to={linkTo}>
                        Go to Robot Io Page
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export const BulkEnableButton = (props) => (
    <BulkActionButton
        {...props}
        actionType="Enable"
        data={{ enabled: true }}
        icon={<Visibility />}
        dialogMessage="Please enable corresponding ROBOT IO!"
        linkTo="/robot_ios"
    />
);

export const BulkDisableButton = (props) => (
    <BulkActionButton
        {...props}
        actionType="Disable"
        data={{ enabled: false }}
        icon={<VisibilityOff />}
        dialogMessage="Please disable corresponding ROBOT IO!"
        linkTo="/robot_ios"
    />
);

const BulkCloneButton = ({ filterValues, selectedIds, ...props }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    async function onSubmit({ configurationIds }) {
        try {
            await dataProvider.create('robot_attributes_bulkclone_executions', {
                data: {
                    targetConfigurations: configurationIds,
                    attributes: selectedIds.map(id => ({ id: id })),
                }
            });
            notify('Robot attributes cloned!');
            setOpen(false);
        } catch (error) {
            console.error(error);
            notify('Robot attribute cloning failed!');
        }
    }
    return (
        <>
            <Button {...props} onClick={() => setOpen(true)} label="Clone">
                {<LibraryAdd />}
            </Button>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle id="simple-dialog-title">Clone to new robot</DialogTitle>
                <DialogContent>
                    <Create {...props}>
                        <SimpleForm onSubmit={onSubmit}>
                            <Labeled label="Robot attributes" fullWidth>
                                <SingleFieldList linkType="show">
                                    <FunctionField render={(record) => (
                                        (selectedIds.includes(record.id)
                                        ? (<ChipField source="name" />) : "")
                                    )} />
                                </SingleFieldList>
                            </Labeled>
                            <ReferenceArrayInput sort={{ field: '', order: 'ASC' }} label="Target configurations"
                                source="configurationIds" reference="robots" fullWidth>
                                <AutocompleteArrayInput optionText={robotRenderer} validate={[required()]} />
                            </ReferenceArrayInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

const BulkActionButtons = props => (
    <Fragment>
        <BulkEnableButton />
        <BulkDisableButton />
        <BulkCloneButton {...props} />
    </Fragment>
);

const ResetFieldsButton = ({ fields, ...props }) => {
    const { setValue } = useFormContext();
    const handleClick = useCallback(() => {
        fields.forEach(field => {
            setValue(field, undefined);
        });
    }, [fields, setValue]);
    return (
        <Button onClick={handleClick} {...props} />
    );
};

const AttributeNameInput = (props) => {
    const { optionItems } = props;
    const { setValue } = useFormContext();
    const optionId = useWatch({ name: 'optionId' });
    const optionName = optionItems.find(item => item.optionId === optionId)?.optionName || '';
    useEffect(() => {
        setValue('name', toAttributeName(optionName));
    }, [optionName, setValue]);
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextInput source="name" defaultValue={toAttributeName(optionName)} style={{ marginRight: '15px' }} />
            <Button color="primary" onClick={() => setValue('name', toAttributeName(optionName))} label="Reset to default name" />
        </div>
    );
};

const OptionSelect = (props) => {
    const { formData, optionItems, setOptionItems, ...rest } = props;
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (formData?.attributeId !== undefined) {
            dataProvider.getList("product_options", {
                pagination: {},
                sort: {},
                filter: {
                    attributeId: formData.attributeId
                },
            }).then(({ data }) => {
                if (data) {
                    setOptionItems(data.filter(attribute => !formData?.attributeOptions?.find(option =>
                        option?.optionId === attribute?.optionId && formData?.optionId !== option?.optionId)) || []);
                }
            }).catch(error => {
                console.log('error', error);
            });
        }
    }, [formData?.attributeId]);
    return (
        <SelectInput
            validate={[required()]}
            label="Option"
            {...rest}
            resettable
            choices={optionItems}
        />
    );
}

const AttributeSelect = (props) => {
    const { setValue } = useFormContext();
    const handleAttributeChange = () => {
        setValue("optionId", undefined);
    }
    return (<AutocompleteInput {...props} onChange={handleAttributeChange}/>);
}

export const AttributeList = props => {
    return (
        <List {...props} perPage={50} sort={{ field: '', order: '' }}
            filters={filters}
            filterDefaultValues={{ configuration: 0, optionIds: [], isAttribute: true }} pagination={<BluehillPagination />}>
            <Datagrid bulkActionButtons={<BulkActionButtons />} >
                <TextField source="id" />
                <TextField source="configuration" />
                <TextField source="name" />
                <TextField source="programKey" />
                <TextField source="productId" label="Product" />
                <TextField source="productMap" label="Product map (legacy)" />
                <ReferenceField label="Attribute" source="attributeId" reference="product_attributes_metadata" >
                    <TextField source="attributeName" />
                </ReferenceField>
                <ReferenceField label="Option" source="optionId" reference="product_options_metadata" >
                    <TextField source="optionName" />
                </ReferenceField>
                <BooleanField source="enabled" />
                <DisabledReasonField label="Disabled reason" />
                <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                <ShowButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
}

export const AttributeCreate = props => {
    const [optionItems, setOptionItems] = React.useState([]);

    return (<Create {...props}>
        <SimpleForm>
            <NumberInput label="Configuration" source="configuration" alwaysOn />
            <ReferenceInput sort={{ field: '', order: 'ASC' }} source="attributeId" reference="product_attributes_metadata" >
                <AttributeSelect optionText={attributeRenderer} queryFields={['attributeName']} validate={[required()]} />
            </ReferenceInput>
            <FormDataConsumer>
                {({
                    formData,
                    ...rest
                }) => {
                    return (
                        <OptionSelect
                            {...rest}
                            formData={formData}
                            label="Option"
                            source="optionId"
                            optionValue="optionId"
                            optionText="optionName"
                            optionItems={optionItems}
                            setOptionItems={setOptionItems}
                        />
                    );
                }}
            </FormDataConsumer>
            <AttributeNameInput source="name" optionItems={optionItems} />
            <ReferenceInput sort={{ field: 'id', order: 'ASC' }} perPage={200} source="programKey" reference="robot_program_keys">
                <AutocompleteInput optionText="id" queryFields={['id']} validate={[required()]} defaultValue="*" />
            </ReferenceInput>
            <BooleanInput source="enabled" defaultValue={true} />
            <ExpandableContent buttonText="Legacy fields">
                <ReferenceInput sort={{ field: 'id', order: 'ASC' }} source="productId" reference="product_metadata">
                    <AutocompleteInput optionText={productMetadataRenderer} queryFields={['productName']} fullWidth />
                </ReferenceInput>
                <ReferenceInput sort={{ field: 'id', order: 'ASC' }} source="productMap" reference="product_metadata">
                    <AutocompleteInput optionText={productMetadataRenderer} queryFields={['productName']} fullWidth />
                </ReferenceInput>
                <ResetFieldsButton label="Reset" fields={["productId", "productMap"]} value={undefined} 
                    variant="outlined" fullWidth />
            </ExpandableContent>
        </SimpleForm>
    </Create>);
}

export const AttributeShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="configuration" />
            <TextField source="name" />
            <TextField source="programKey" />
            <TextField source="productId" label="Product" />
            <TextField source="productMap" label="Product map (legacy)" />
            <ReferenceField label="Attribute" source="attributeId" reference="product_attributes_metadata" >
                <TextField source="attributeName" />
            </ReferenceField>
            <ReferenceField label="Option" source="optionId" reference="product_options_metadata" >
                <TextField source="optionName" />
            </ReferenceField>
            <BooleanField source="enabled" />
            <DisabledReasonField label="Disabled reason" />
            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
            <Labeled label="Modifiers" >
                <JsonField source="modifiers" />
            </Labeled>
            <DeleteButton />
        </SimpleShowLayout>
    </Show>
)
