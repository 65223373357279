import React, { useEffect } from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    Labeled,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import { BluehillPagination } from './bluehillPagination';

const toProgramName = (programKey) => {
    if (programKey) {
        const programKeyParts = programKey.split("|");
        var result = snakeCaseToName(programKeyParts[0]);
        if (programKeyParts.length >= 2) {
            result += ' | ';
            result += parseProgramKeyPart1(programKeyParts[1]);
        }
        return result;
    } else {
        return '';
    }
};

const snakeCaseToName = (programKeyPart0) => {
    const strs = programKeyPart0.split("_");
    var result = '';
    for (var i = 0; i < strs.length; i++) {
        result += toUpperCamelCase(strs[i]);
        if (i < strs.length - 1) {
            result += ' ';
        }
    }
    return result;
};

const parseProgramKeyPart1 = (programKeyPart1) => {
    const strs = programKeyPart1.split(",");
    var result = '';
    for (var i = 0; i < strs.length; i++) {
        result += snakeCaseToName(strs[i]);
        if (i < strs.length - 1) {
            result += ',';
        }
    }
    return result;
};

const toUpperCamelCase = (str) => {
    if (str) {
        var result = '';
        if (str.length >= 1) {
            result += str.charAt(0).toUpperCase();
        }
        for (var i = 1; i < str.length; i++) {
            result += str.charAt(i).toLowerCase();
        }
        return result;
    } else {
        return '';
    }
};

const ProgramNameCreatInput = props => {
    const { setValue } = useFormContext();
    const programKey = useWatch({ name: 'programKey' });

    useEffect(() => {
        setValue('taskName', toProgramName(programKey));
    }, [programKey, setValue]);

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <TextInput source="programName" defaultValue={toProgramName(programKey)} autoWidth="true" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button color="primary" onClick={() => setValue('programName', toProgramName(programKey))}>Reset to default name</Button>
        </div>
    );
};

const ProgramNameEditInput = props => {
    const { setValue } = useFormContext();
    const programKey = useWatch({ name: 'programKey' });

    useEffect(() => {
        setValue('taskName', toProgramName(programKey));
    }, [programKey, setValue]);
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            <TextInput source="programName" autoWidth="true" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button color="primary" onClick={() => setValue('programName', toProgramName(programKey))}>Reset to default name</Button>
        </div>
    );
};

const filters = [
    <TextInput label="Program Key" source="partialProgramKey" alwaysOn />,
];

export const ProgramKeyList = props => (
    <List {...props} perPage={50} sort={{field:'', order:''}}
        filters={filters} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false} >
            <TextField source="programKey" />
            <EditButton />
        </Datagrid>
    </List>
);

export const ProgramKeyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Labeled label="Program Key">
                <TextField source="programKey" />
            </Labeled>
        </SimpleForm>
    </Edit>
);

export const ProgramKeyCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm >
                <TextInput source="programKey" />
            </SimpleForm>
        </Create>
    );
};