import Keycloak from 'keycloak-js';
import { bluehillKeyCloakDomain , keycloakRealm } from './systemContext';

export const keycloak = new Keycloak({
  url: bluehillKeyCloakDomain() + '/auth',
  realm: keycloakRealm(),
  clientId: 'store-mgr-web',
  onLoad: 'login-required'
});

export const keycloakConfig = {
  initOptions: {
    onLoad: 'login-required'
  }
};
