import { fetchUtils, HttpError } from 'react-admin';
import { store, getList } from './bluehillReducer';
import { stringify } from 'query-string';
import { getEndpoint } from './bluehillApi';
import { getToken } from './bluehillAuthDao';

const convertFiltersToQueryParams = (params) => {
    const query = {};
    if (typeof params.filter?.['pagination.firstItem'] !== 'undefined') {
        query['pagination.firstItem'] = params.filter['pagination.firstItem'];
        delete params.filter?.['pagination.firstItem'];
    }
    if (typeof params.filter?.['pagination.lastItem'] !== 'undefined') {
        query['pagination.lastItem'] = params.filter['pagination.lastItem'];
        delete params.filter?.['pagination.lastItem'];
    }
    if (typeof params.filter?.['pagination.scanForward'] !== 'undefined') {
        query['pagination.scanForward'] = params.filter['pagination.scanForward'];
        delete params.filter['pagination.scanForward'];
    }
    if (typeof params.pagination?.perPage !== 'undefined') {
        query['pagination.pageSize'] = params.pagination.perPage;
        delete params.pagination.perPage;
    }
    Object.entries(params?.filter ?? {}).forEach(entry => {
        if (entry[0] !== 'object') {
            query[entry[0]] = entry[1];
        } else {
            if (entry[1].id != null) {
                query['object.id'] = `${entry[1].id}`;
            }
            if (entry[1].type) {
                query['object.type'] = entry[1].type;
            }
        }
    });
    return query;
}


async function request(resource, type, params = null, options = {}) {
    const baseUrl = getEndpoint(resource, type, params);
    const url = `${baseUrl}${params ? `?${stringify(params)}` : ''}`;
    if (!options.headers) {
        options.headers = new Headers({});
    }
    options.headers.set('Accept', 'application/json');
    options.headers.set('Authorization', 'Bearer ' + getToken());

    try {
        const result = await fetchUtils.fetchJson(url, options);
        return result;
    } catch (error) {
        if (error instanceof HttpError) {
            throw new HttpError(error.body?.error?.message || error.body?.message || error.message, error.status, error.body);
        }
        throw error;
    }
}

const dataProvider = {
    getList(resource, params) {
        const { sort } = params;
        const query = convertFiltersToQueryParams(params);
        if (resource === 'product_program_key_relations') {
            if (!params.filter?.configurations) {
                return Promise.resolve({
                    data: [],
                    total: 1,
                    pageInfo: {
                        hasPreviousPage: false,
                        hasNextPage: false,
                    }
                });
            }
        }
        return request(resource, 'getList', query).then(({ json }) => {
            if (sort?.field && json?.results?.length) {
                json.results.sort(localSort(sort?.field, sort?.order))
            }

            if (json.pagination) {
                store.dispatch(getList({ pagination: json.pagination }));
            }

            return {
                data: json.results || [],
                total: 1, // Set any positive number. Custom pagination doesn't use this field
                pageInfo: {
                    hasPreviousPage: !json?.pagination?.firstPage,
                    hasNextPage: !json?.pagination?.lastPage,
                }
            };
        });
    },

    getOne(resource, params) {
        return request(resource, 'getOne', params).then(({ json }) => {
            if (resource === 'orders_waiting_info') {
                if (json.id === undefined) {
                    json.id = params.id;
                }
            }
            if (resource === 'product_attributes') {
                if (json.id === undefined) {
                    json.id = json.productId;
                }
            }
            if (resource === 'orders_email_receipt_info') {
                if (json.id === undefined) {
                    json.id = params.id;
                }
            }
            if (resource === 'robots/~/modules/computer/properties/volume') {
                if (json.id === undefined) {
                    json.id = params.id;
                }
            }
            if (resource === 'robot_common_ios_control_data') {
                json.commonId = json.id;
                json.id = json.ioControlKey;
            }
            return ({
                data: json,
            })
        });
    },

    getMany(resource, { ids, meta, ...params }) {
        let requestParams = { ids, meta };
        if (resource === 'product_options_metadata' && meta?.customKey) {
            requestParams = {
                [meta.customKey]: ids
            };
        }
        
        return request(resource, 'getMany', requestParams).then(({ json }) => ({
            data: json.results,
        }));
    },

    getManyReference(resource, { target, id, ...params }) {
        const { sort, filter = {} } = params;
        const query = { ...convertFiltersToQueryParams(params), ...filter, [target]: id };

        return request(resource, 'getList', query).then(({ json }) => {
            if (sort?.field && json?.results?.length) {
                json.results.sort(localSort(sort?.field, sort?.order))
            }

            if (json.pagination) {
                store.dispatch(getList({ pagination: json.pagination }));
            }
            return {
                data: json.results,
                total: 1,
                pageInfo: {
                    hasPreviousPage: !json?.pagination?.firstPage,
                    hasNextPage: !json?.pagination?.lastPage,
                }
            }
        });
    },

    update(resource, { id, data }) {
        if (resource === "product_metadata") {
            return this.updateProductMetadataMultipart(resource, {id, data});
        } else if (resource === "stores") {
            return this.updateStoreMultipart(resource, {id, data});
        } else if (resource === "product_groups") {
            return this.updateProductGroupMultipart(resource, {id, data});
        }

        return request(resource, 'getOne', { id }, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(({ json }) => ({
            data: { id }
        }));
    },

    updateProductMetadataMultipart(resource, { id, data }) {
        let formData = new FormData();
        for (const key in data) {
            if (key === "productImageFile" && data[key]) {
                formData.append(key, data[key].rawFile);
            } else if (key === "productImages") {
                for (const imgKey in data[key]) {
                    formData.append(key + "." + imgKey, data[key][imgKey]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }
        return request(resource, 'getOne', { id }, {
            method: 'PUT',
            body: formData
        }).then(({ json }) => ({
            data: { id }
        }));
    },

    updateStoreMultipart(resource, { id, data }) {
        let formData = new FormData();
        for (const key in data) {
            if (key === "storeImageFile" && data[key]) {
                formData.append(key, data[key].rawFile);
            } else if (key === "paymentInfo") {
                for (const subKey in data[key]) {
                    formData.append(key + "." + subKey, data[key][subKey]);
                }
            } else {
                formData.append(key, data[key]);
            }
        }
        return request(resource, 'getOne', { id }, {
            method: 'PUT',
            body: formData
        }).then(({ json }) => ({
            data: { id }
        })).catch((ex) => {
            console.error(ex);
        });
    },

    updateProductGroupMultipart(resource, { id, data }) {
        let formData = new FormData();
        for (const key in data) {
            if (key === "groupImageFile" && data[key]) {
                formData.append(key, data[key].rawFile);
            } else {
                formData.append(key, data[key]);
            }
        }
        return request(resource, 'getOne', { id }, {
            method: 'PUT',
            body: formData
        }).then(({ json }) => ({
            data: { id }
        })).catch((ex) => {
            console.error(ex);
        });
    },

    updateMany(resource, { ids, data }) {
        return request(resource, "updateMany", { ids }, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(({ json }) => ({
            data: json ? json?.result : []
        }));
    },

    create(resource, params = {}) {
        if (resource === "product_metadata") {
            return this.createProductMetadataMultipart(resource, params);
        } else if(resource === "stores") {
            return this.createStoreMultipart(resource, params);
        } else if (resource === "product_groups") {
            return this.createProductGroupMultipart(resource, params);
        } else if (resource === 'robot_imported_data_batches') {
            return this.createMultipart(resource, params, 'robotConfigurationFile');
        }

        return request(resource, 'create', params, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { id: json?.id }
        }));
    },

    createMultipart(resource, params, fileKey) {
        const formData = new FormData();
        for (const key in params.data) {
            if (key === fileKey && params.data[key]) {
                formData.append(key, params.data[key].rawFile);
            } else {
                formData.append(key, params.data[key]);
            }
        }

        return request(resource, 'create', params, {
            method: 'POST',
            body: formData
        }).then(({ json }) => ({
            data: { id: json?.id }
        }));
    },

    createProductMetadataMultipart(resource, params) {
        let formData = new FormData();
        for (const key in params.data) {
            if (key === "productImageFile" && params.data[key]) {
                formData.append(key, params.data[key].rawFile);
            } else if (key === "productImages") {
                for (const imgKey in params.data[key]) {
                    formData.append(key + "." + imgKey, params.data[key][imgKey]);
                }
            } else {
                formData.append(key, params.data[key]);
            }
        }
        return request(resource, 'create', params, {
            method: 'POST',
            body: formData
        }).then(({ json }) => ({
            data: { id: json?.id }
        }));
    },

    createStoreMultipart(resource, params) {
        let formData = new FormData();
        for (const key in params.data) {
            if (key === "storeImageFile" && params.data[key]) {
                formData.append(key, params.data[key].rawFile);
            } else if (key === "paymentInfo") {
                for (const subKey in params.data[key]) {
                    formData.append(key + "." + subKey, params.data[key][subKey]);
                }
            } else {
                formData.append(key, params.data[key]);
            }
        }
        return request(resource, 'create', params, {
            method: 'POST',
            body: formData
        }).then(({ json }) => ({
            data: { id: json?.id }
        }));
    },

    createProductGroupMultipart(resource, params) {
        let formData = new FormData();
        for (const key in params.data) {
            if (key === "groupImageFile" && params.data[key]) {
                formData.append(key, params.data[key].rawFile);
            } else {
                formData.append(key, params.data[key]);
            }
        }
        return request(resource, 'create', params, {
            method: 'POST',
            body: formData
        }).then(({ json }) => ({
            data: { id: json?.id }
        }));
    },

    delete(resource, params) {
        return request(resource, 'getOne', params, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: {} }));
    },

    deleteMany(resource, { ids }) {
        return request(resource, null, { ids }, {
            method: 'DELETE',
        }).then(({ json }) => ({
            data: []
        }));
    }
};

const localSort = (property, order) => {
    const sortOrder = order === 'DESC' ? -1 : 1;
    return function (a, b) {
        let aProp = a[property];
        let bProp = b[property];
        if (!a.hasOwnProperty(property)) {
            aProp = ''
        }
        if (!b.hasOwnProperty(property)) {
            bProp = ''
        }
        return ((aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0) * sortOrder;
    }
}

export default dataProvider;
