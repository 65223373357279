import { useKeycloak } from '@react-keycloak/web';
import { setToken, removeToken, setRoles, removeRoles } from './bluehillAuthDao';

const KeycloakAuthProvider = () => {
    const { keycloak } = useKeycloak();
    keycloak.onTokenExpired = function() {
        keycloak.updateToken()
            .then(function(refreshed) {
                if (refreshed) {
                    setToken(keycloak.token);
                    console.log('Token was successfully refreshed');
                } else {
                    console.log('Token is still valid');
                }
            }).catch(function() {
                console.log('Failed to refresh the token, or the session has expired');
            });
    };
    return ({
        login: () => keycloak.login(),
        checkError: () => Promise.resolve(),
        checkAuth: () => {
            if (keycloak.authenticated && keycloak.token) {
                setToken(keycloak.token);
                return Promise.resolve();
            } else {
                return Promise.reject("Failed to obtain access token.");
            }
        },
        logout: () =>  {
            removeToken();
            removeRoles();
            keycloak.logout();
        },
        getIdentity: () => {
            if (keycloak.tokenParsed) {
                const decoded = keycloak.tokenParsed;
                const id = decoded.sub
                const fullName = decoded.name
                return Promise.resolve({id, fullName});
            }
            return Promise.reject("Failed to get identity");
        },
        getPermissions:() => {
            if (keycloak.tokenParsed) {
                setRoles(keycloak.tokenParsed.realm_access?.roles);
            }
            return Promise.resolve(true);
        },
    });
};

export default KeycloakAuthProvider;