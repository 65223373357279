import * as React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    EditButton,
    ShowButton,
    Show,
    Edit,
    ImageField,
    DeleteButton,
    Create,
    SimpleForm,
    BooleanInput,
    ImageInput,
    RadioButtonGroupInput,
    SaveButton,
    Toolbar,
    required,
    FunctionField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    TabbedForm,
    ReferenceArrayInput,
    SimpleShowLayout,
    useNotify,
    useRedirect,
    useEditController,
    useDataProvider,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ExpandableContent } from "./components/ExpandableContent";
import AutocompleteArrayInput from './components/BaseAutocompleteArrayInput';
import { BluehillPagination } from './bluehillPagination';

const categoryRenderer = (group) => `${group.groupType}: ${group.groupName}`;

const filters = [
    <TextInput label="Product Name" source="productName" alwaysOn />,
];

export const ProductMetadataList = props => {
    return (
        <List {...props} filters={filters} sort={{ field:'', order:'' }} perPage={500} pagination={<BluehillPagination />}>
            <Datagrid >
                <TextField label="Distinct Product Id" source="distinctProductId" />
                <TextField label="Product Name" source="productName" />
                <TextField label="Product Key" source="productKey" />
                <TextField label="Sequence Number" source="sequenceNumber" />
                <BooleanField label="Supported By Robot" source="supportedByRobot" />
                <BooleanField label="Applied to coupons" source="appliedToCoupons" />
                <ReferenceArrayField source="groupKeys" reference="product_groups" label="Product categories">
                    <SingleFieldList>
                        <FunctionField render={(record) => (
                            <ChipField record={{ group: `${categoryRenderer(record)}` }} source="group" />
                        )} />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ShowButton />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>)
};

export const ProductMetadataShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="distinctProductId" />
                <TextField source="productName" />
                <TextField source="shortProductName" />
                <TextField source="productKey" />
                <TextField source="shortDescription" />
                <TextField source="longDescription" />
                <TextField source="internalNotes" />
                <TextField source="sequenceNumber" />
                <BooleanField source="status" />
                <BooleanField source="supportedByRobot" />
                <BooleanField source="appliedToCoupons" />
                <ReferenceArrayField source="groupKeys" reference="product_groups" label="Product categories">
                    <SingleFieldList>
                        <FunctionField render={(record) => (
                            <ChipField record={{ group: `${categoryRenderer(record)}` }} source="group" />
                        )} />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ImageField source="productImages.primaryImageUrl" label="Primary Image" />
                <ImageField source="productImages.primaryImageThumbnailUrl" label="Primary Image Thumbnail" />
            </SimpleShowLayout>
        </Show>
    );
};

export const ProductMetadataCreate = props => {
    const [uploadMode, setUploadMode] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState(false);
    return (<Create {...props}>
        <SimpleForm
            toolbar={<CustomToolbar imageUploaded={imageUploaded} uploadMode={uploadMode} deleteButton={false} />}
            sanitizeEmptyValues
        >
            <TextInput source="productName" validate={[required()]} />
            <TextInput source="shortProductName" />
            <TextInput source="sequenceNumber" defaultValue={100000}
                helperText="Display position, the smaller the number, the higher the sorting" />
            <TextInput source="shortDescription" multiline fullWidth />
            <TextInput source="longDescription" multiline fullWidth />
            <TextInput source="internalNotes" multiline fullWidth />
            <BooleanInput source="status" defaultValue={true} />
            <BooleanInput source="supportedByRobot" />
            <BooleanInput source="appliedToCoupons" />
            <ImageUpload imageUploaded={imageUploaded} uploadMode={uploadMode}
                setImageUploaded={setImageUploaded} setUploadMode={setUploadMode}></ImageUpload>
            <ExpandableContent buttonText="Developer Use Only">
                <TextInput source="productKey" fullWidth />
            </ExpandableContent>
        </SimpleForm>
    </Create>);
};

export const ProductMetadataEdit = props => {
    const [uploadMode, setUploadMode] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState(false);

    const controllerProps = useEditController(props);

    const { save } = controllerProps;
    const dataProvider = useDataProvider();
    const location = useLocation();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSave = async (data) => {
        const { groupKeys, ...entity } = data;
        try {
            if (location.pathname.split('/').pop().includes('categories')) {
                await dataProvider.update('product_metadata_groups_relation', {
                    id: data.distinctProductId,
                    data: {
                        groupKeys: data.groupKeys
                    }
                });
                redirect('list', '/product_metadata');
                notify(`Element updated!`);
            } else {
                save(entity);
            }
        } catch (error) {
            notify(`Update failed!`);
        }
    }

    return (
        <Edit {...props}>
            <TabbedForm
                onSubmit={onSave}
                toolbar={<CustomToolbar imageUploaded={imageUploaded} uploadMode={uploadMode} deleteButton={true} />}
                sanitizeEmptyValues
            >
                <TabbedForm.Tab label="Metadata">
                    <TextField source="distinctProductId" />
                    <TextInput source="productName" validate={[required()]} />
                    <TextInput source="shortProductName" />
                    <TextInput source="sequenceNumber" defaultValue={100000} helperText="Display position, the smaller the number, the higher the sorting" />
                    <TextInput source="shortDescription" multiline fullWidth />
                    <TextInput source="longDescription" multiline fullWidth />
                    <TextInput source="internalNotes" multiline fullWidth />
                    <BooleanInput source="status" />
                    <BooleanInput source="supportedByRobot" />
                    <BooleanInput source="appliedToCoupons" />
                    <ImageField source="productImages.primaryImageUrl" label="Current primary image" />
                    <ImageField source="productImages.primaryImageThumbnailUrl" label="Current primary image thumbnail" />
                    <ImageUpload imageUploaded={imageUploaded} uploadMode={uploadMode}
                        setImageUploaded={setImageUploaded} setUploadMode={setUploadMode}></ImageUpload>
                    <ExpandableContent buttonText="Developer Use Only">
                        <TextInput source="productKey" fullWidth />
                    </ExpandableContent>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Product Categories" path="categories">
                    <ReferenceArrayInput sort={{ field: 'groupKey', order: 'ASC' }} 
                        source="groupKeys" reference="product_groups" label="Product categories">
                        <AutocompleteArrayInput
                            optionText={categoryRenderer}
                            filterToQuery={(v) => ({ groupName: v })}
                            renderSuggestion={(v) => v.length > 1}
                            validate={[required()]}
                        />
                    </ReferenceArrayInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>);
};

export const CustomToolbar = ({ uploadMode, imageUploaded, deleteButton, ...props }) => (
    <Toolbar {...props} style={{ "justifyContent": "space-between" }}>
        <SaveButton disabled={(!uploadMode && imageUploaded)} />
        {deleteButton && <DeleteButton />}
    </Toolbar>
);

export const ImageUpload = ({ uploadMode, setUploadMode, imageUploaded, setImageUploaded, ...props }) => {
    return (<>
        <RadioButtonGroupInput {...props} source="productImageSource" defaultValue="upload" choices={[
            { id: 'upload', name: 'Upload' },
            { id: 'url', name: 'URL' },
        ]}
            onChange={() => setUploadMode(!uploadMode)}
        />
        {uploadMode &&
            (<ImageInput {...props} source="productImageFile" label="Upload primary image" 
                onChange={(image) => setImageUploaded(image != null)}  >
                <ImageField source="src" title="title" />
            </ImageInput>)}
        <br />
        {(!uploadMode && imageUploaded) && <><span style={{ "color": "red" }}>
            Image was uploaded. Remove the uploaded image if you'd like to enter a URL instead.</span><br /></>}
        {!uploadMode && <><TextInput {...props} source="productImages.primaryImageUrl" label="Set primary image URL" /><br />
            <TextInput {...props} source="productImages.primaryImageThumbnailUrl" label="Set primary image thumbnail URL" /></>}
    </>);
};