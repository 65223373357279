import React, { useEffect, useState } from "react";

import {
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    required,
    Create,
    SimpleForm,
    ListToolbar,
    Title,
    ListBase,
    List,
    BooleanInput,
    SelectInput,
    ReferenceField,
    ReferenceInput,
    Button,
    useDataProvider,
    useNotify,
    BulkDeleteButton,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    CheckboxGroupInput,
    useListContext,
    useInput,
    Link,
    useRecordContext,
} from 'react-admin';
import { Card, Dialog, DialogTitle, DialogContent } from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import AutocompleteInput from './components/BaseAutocompleteInput';

const BulkCloneButton = ({ filterValues, selectedIds, ...props }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    async function onSubmit({ configurations }) {
        try {
            await dataProvider.create('robot_product_programKey_relations_bulkclone_executions', {
                data: {
                    targetConfigurations: configurations,
                    productProgramKeyRelationIds: selectedIds,
                }
            });
            notify('Product ProgramKey Relations cloned!');
            setOpen(false);
        } catch (error) {
            console.error(error);
            notify('Product ProgramKey Relations cloning failed!');
        }
    }
    return (
        <>
            <Button onClick={() => setOpen(true)} label="Clone">{<LibraryAddIcon />}</Button>
            <Dialog open={open} maxWidth="md" fullWidth onClose={() => setOpen(false)}>
                <DialogTitle id="simple-dialog-title">Clone to new configurations</DialogTitle>
                <DialogContent>
                    <Create {...props}>
                        <SimpleForm onSubmit={onSubmit}>
                            <ArrayInput source="configurations">
                                <SimpleFormIterator inline disableReordering>
                                    <NumberInput helperText={false} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </>
    );
};

const BulkActionButtons = (props) => {
    return (
        <>
            <BulkCloneButton {...props} />
            <BulkDeleteButton {...props} />
        </>
    );
};

const isSearchEnabled = (searchText) => {
    return searchText.trim().length === 0
        ||searchText.trim() === '*'
        || searchText.trim().length >= 3;
};


const filters = [
    <ReferenceInput label="Store" source="storeId" reference="stores"  alwaysOn>
        <AutocompleteInput optionText="storeName" queryFields={['storeName']} validate={[required()]} />
    </ReferenceInput>,
    <CustomizedInput source="configurations" alwaysOn />,
    <ReferenceInput source="programKey" reference="robot_program_keys" alwaysOn>
        <AutocompleteInput
            optionText="programKey"
            queryFields={['programKey']}
            filterToQuery={searchText => ({ partialProgramKey: searchText })} 
            sx={{ width: 250 }}
            shouldRenderSuggestions={searchText => searchText.trim().length >= 3}
        />
    </ReferenceInput>,
    <ReferenceInput label="Product Name" source="distinctProductId" reference="product_metadata" alwaysOn>
        <AutocompleteInput optionText="productName" 
            queryFields={['productName']}
            filterToQuery={searchText => ({ productName: searchText })}
            shouldRenderSuggestions={searchText => searchText.trim().length >= 3} />
    </ReferenceInput>,
    <BooleanInput label="Enabled" source="enabled" />,
];

function CustomizedInput() {
    const storeIdField = useInput({ source: 'storeId' });

    const dataProvider = useDataProvider();
    const { filterValues, setFilters } = useListContext();
    const { configurations: filterConfigurations = [], ...others } = filterValues;
    const [configurations, setConfigurations] = useState([]);

    useEffect(() => {
        if (!storeIdField?.field) {
            return;
        }
        dataProvider.getList('robots', {
            pagination: {},
            sort: { field: "configuration", order: "ASC" },
            filter: {
                storeId: storeIdField.field.value,
            }
        })
            .then(({ data }) => {
                const configurationsSet = new Set(data.map(item => item.configuration));
                const storeConfigurations = data.length ? Array.from(configurationsSet) : [];
                const availableConfigurations = filterConfigurations.filter(item => configurationsSet.has(item));
                if (availableConfigurations.length) {
                    if (availableConfigurations.length !== filterConfigurations.length) {
                        setFilters({
                            ...others,
                            storeId: storeIdField.field.value,
                            configurations: availableConfigurations,
                        });
                    }
                } else {
                    setFilters({
                        ...others,
                        storeId: storeIdField.field.value,
                        configurations: storeConfigurations,
                    });
                }
                setConfigurations(storeConfigurations.map(item => ({ id: item, name: item.toString() })));
            })
            .catch((error) => {
                console.error(error);
            });
    }, [dataProvider, storeIdField.field.value]);

    return (
        <CheckboxGroupInput className="product-program-key-relations-filter-configurations" source="configurations" choices={configurations} />
    );
}

export const ProductProgramKeyRelationList = props => {
    const notify = useNotify();
    const onFailure = (err) => {
        if (err?.message !== 'Required request parameter \'configurations\' for method parameter type Long[] is not present') {
            notify(err.message);
        }
    }
    return (
        <List {...props} sort={{ field:'', order:'' }} filters={filters}
            filterDefaultValues={{ configurations: [], storeId: 0 }}
            pagination={false}
            queryOptions={{
                onError: onFailure
            }}
        >
            <Datagrid  bulkActionButtons={<BulkActionButtons />} >
                <TextField label="Configuration" source="configuration" />
                <ReferenceField label="Product Name" source="distinctProductId" reference="product_metadata" link="show">
                    <TextField source="productName" />
                </ReferenceField>
                <TextField label="Program Key" source="programKey" />
                <BooleanField label="Enabled" source="enabled" />
            </Datagrid>
        </List>
    );
}

export const ProductProgramKeyRelationCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="programKey" reference="robot_program_keys">
                <AutocompleteInput optionText="programKey"
                    queryFields={["programKey"]}
                    filterToQuery={searchText => ({ partialProgramKey: searchText })}
                    shouldRenderSuggestions={searchText => isSearchEnabled(searchText)} />
            </ReferenceInput>
            <ReferenceInput label="Distinct Product Name" source="distinctProductId" reference="product_metadata">
                <AutocompleteInput
                    label="Product Name"
                    optionText="productName"
                    optionValue="distinctProductId"
                    queryFields={["productName"]}
                    filterToQuery={searchText => ({ productName: searchText })}
                    validate={[required()]} />
            </ReferenceInput>
            <TextInput source="configuration" validate={[required()]}/>
            <BooleanInput source="enabled" defaultValue={true}/>
        </SimpleForm>
    </Create>
);