import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    TextField,
    EditButton,
    ShowButton,
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    SimpleShowLayout,
    Labeled,
} from 'react-admin';
import { JsonField, JsonInput } from "react-admin-json-view";
import { BluehillPagination } from './bluehillPagination';


const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
    <NumberInput source="subtypeId" alwaysOn />,
];

export const RobotObjectList = props => (
    <List {...props} 
        perPage={100} sort={{ field: '', order: '' }}
        filters={filters} filterDefaultValues={{ configuration: 0 }} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="configuration" />
            <TextField source="name" />
            <TextField source="type" />
            <TextField source="subtypeId" />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

const taskOptionRenderer = task => {
    if (!task) {
        return '';
    } else {
        return task.id + ": " + task.name;
    }
}; 

export const RobotObjectShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="configuration" />
                <TextField source="name" />
                <TextField source="type" />
                <TextField source="subtypeId" />
                <JsonField source="properties" />
            </SimpleShowLayout>
        </Show>
    );
};

export const RobotObjectEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Labeled label="Id">
                <TextField source="id" />
            </Labeled>
            <Labeled label="Configuration">
                <TextField source="configuration" />
            </Labeled>
            <Labeled label="Name">
                <TextField source="name" />
            </Labeled>
            <Labeled label="Type">
                <TextField source="type" />
            </Labeled>
            <Labeled label="SubtypeId">
                <TextField source="subtypeId" />
            </Labeled>
            <JsonInput source="properties" />
        </SimpleForm>
    </Edit>
);