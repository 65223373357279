import React, { useState, useEffect } from 'react';
import {
    ListBase,
    BulkActionsToolbar,
    Title,
    Show,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    ArrayField,
    NumberInput,
    SelectInput,
    SimpleShowLayout,
    ReferenceInput,
    useListContext,
    useShowController,
    useCreatePath,
    required,
    useDataProvider,
    Labeled,
    ReferenceArrayInput,
    useRecordContext,
    Link,
} from 'react-admin';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useForm, FormProvider } from 'react-hook-form';
import { Box } from '@mui/material';

import AuthImage from './components/AuthImage';
import ImageGallery from './components/ImageGallery';
import AutocompleteInput from './components/BaseAutocompleteInput';
import AutocompleteArrayInput from './components/BaseAutocompleteArrayInput';
import { BluehillPagination } from './bluehillPagination';

const productNameFilterToQuery = searchText => ({ productName: `%${searchText}%` });


const OrderItemImageList = (props) => {
    const { data, resource, filterValues } = useListContext();

    const createPath = useCreatePath();

    return data ? (
        <ImageGallery
            data={Object.values(data)}
            link={(item) => createPath({ resource, id: item.id, type: 'show' })}
            source={(item) => item.properties[filterValues.imageType]}
            subTitle={(item) => (
                <span>
                    <TextField source="productName" record={item.properties} />
                    &nbsp;&nbsp;
                    <DateField source="createdTime" record={item} showTime />
                </span>
            )}
        />
    ) : null;
}

const FilterFormContainer = () => {
    const { filterValues, setFilters } = useListContext();
    const {
        'pagination.lastItem': paginationLastItem,
        'pagination.firstItem': paginationFirstItem,
        'pagination.scanForward': paginationScanForward,
        ...topFilterValues
    } = filterValues;

    const form = useForm({
        defaultValues: filterValues,
    });

    const onSubmit = (data) => {
        if (Object.keys(data).length > 0) {
            let paginationObj = {};
            if (paginationLastItem && paginationScanForward) {
                paginationObj = { "pagination.lastItem": null, "pagination.scanForward": true }
            }
            setFilters({ ...topFilterValues, ...data, ...paginationObj  })
        }
    };

    const { watch, handleSubmit } = form;

    useEffect(() => {
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleSubmit, watch]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <Box display="flex" alignItems="flex-end" mb={1} sx={{ flexWrap: 'wrap' }}>
                    <Box component="span" mr={2} sx={{ flexShrink: 0 }}>
                        <NumberInput label="Robot ID" source="robotId" alwaysOn />
                    </Box>
                    <Box component="span" mr={2}>
                        <SelectInput label="Which Image?" source="imageType" choices={[
                            { id: 'itemDoneImageUrl', name: 'Done Image' },
                            { id: 'latteArtImageUrl', name: 'Latte Art' },
                            { id: 'milkDispensedImageUrl', name: 'Milk after Dispensing' },
                            { id: 'milkVolumeImageUrl', name: 'Milk Volume' },
                            { id: 'cleanedPortafilterImageUrl', name: 'Portafilter after Cleaning' },
                            { id: 'fullPortafilterImageUrl', name: 'Full Portafilter image' },
                        ]} validate={[required()]} alwaysOn />
                    </Box>
                    <Box component="span" mr={2}>
                        <ReferenceInput source="distinctProductId" reference="product_metadata" alwaysOn>
                            <AutocompleteInput
                                label="Product Name"
                                optionText="productName"
                                optionValue="distinctProductId"
                                queryFields={['productName']}
                                filterToQuery={productNameFilterToQuery} 
                            />
                        </ReferenceInput>
                    </Box>
                    <Box component="span" mr={2}>
                        <ReferenceArrayInput
                            source="optionKeys" reference="product_options_metadata" alwaysOn
                            queryOptions={{ meta: { customKey: 'optionKeys' } }}
                        >
                            <AutocompleteArrayInput optionValue="optionKey" queryFields={['optionKey']} label="Option Key" optionText={(record => `${record.attributeName}: ${record.optionName}`)} />
                        </ReferenceArrayInput>
                    </Box>

                </Box>
            </form>
        </FormProvider>
    );
};

export const OrderItemRecordList = ({ children, actions, bulkActionButtons, filters, title, ...props}) => {
    return (
        <ListBase {...props}  perPage={50} sort={{field:'', order:''}}
            filterDefaultValues={{ robotId: 0, imageType:'itemDoneImageUrl' }}>
            <Title title="Order Items" />
            <FilterFormContainer />
            <Card>
                <BulkActionsToolbar>
                    {bulkActionButtons}
                </BulkActionsToolbar>
                <OrderItemImageList />
                <BluehillPagination />
            </Card>
        </ListBase>
    );
};

function OperationLink() {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const [operationHistoryId, setOperationHistoryId] = useState(null);
    useEffect(() => {
        if (!record?.properties?.length) {
            return;
        }
        const { value } = record?.properties?.find(item => item.key === 'robotId') ?? {};
        if (!value) {
            return;
        }
        dataProvider.getList('operation_history', {
            filter: {
                'object.id': value,
                'object.type': 'ROBOT',
                'subobject.id': record.id,
                'subobject.type': 'ROBOT_ORDER_ITEM'
            },
            pagination: {
                perPage: 1
            }
        }).then(({ data }) => {
            if (data?.length) {
                setOperationHistoryId(data[0].id);
            }
        }).catch((err) => {
            console.error(err);
        });
    }, [record, dataProvider]);

    return operationHistoryId
        ? <Link to={`/operation_history/${operationHistoryId}/show`} variant="body2">{operationHistoryId}</Link>
        : <Typography variant="body2">None</Typography>;
}

OperationLink.defaultProps = {
    label: 'Item'
};

function OrderLink() {
    const { orderId } = useRecordContext();
    return <Link to={`/orders/${orderId}/show`} variant="body2">{orderId}</Link>
}

export const OrderItemRecordShow = props => {
    const { record } = useShowController(props);

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <OperationLink label="Operation history" />
                <TextField source="id" />
                <Labeled label="Order">
                    <OrderLink />
                </Labeled>
                <ReferenceField label="Order Item" source="itemId" reference="order_items" link={false}>
                    <TextField source="productName" />
                </ReferenceField>
                <ReferenceField label="Order Item" source="itemId" reference="order_items" link={false}>
                    <ArrayField label="Options" source="attributeOptions">
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="attributeName" />
                            <TextField source="optionName" />
                        </Datagrid>
                    </ArrayField>
                </ReferenceField>
                <dl key="recordImageDl">
                {
                    record && record.properties && Array.isArray(record.properties) ?
                        record.properties.map((property) => {
                            if (property.type === 'IMAGE') {
                                return (
                                    <dl key={property.displayName + "dl"}>
                                        <dt key={property.displayName + "dt"}>{property.displayName}</dt>
                                        <dd key={property.displayName + "dd"}><AuthImage url={property.value} width="600" /></dd>
                                    </dl>
                                );
                            } else {
                                return <div key={property.displayName + "div"}/>;
                            }
                        }):
                        <div/>
                }
                </dl>
                <dl key="recordTextDl">
                {
                    record && record.properties && Array.isArray(record.properties) ?
                        record.properties.map((property) => {
                            if (property.type !== 'IMAGE') {
                                return (
                                    <dl key={property.displayName + "dl"}>
                                        <dt key={property.displayName + "dt"}>{property.displayName}</dt>
                                        <dd key={property.displayName + "dd"}><TextField label={property.displayName} source="value" record={property} /></dd>
                                    </dl>
                                );
                            } else {
                                return <div key={property.displayName + "div"}/>;
                            }
                        }):
                        <div/>
                }
                </dl>
            </SimpleShowLayout>
        </Show>
    );
};