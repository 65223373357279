import React, { useState } from 'react';
import { Menu, MenuItemLink } from 'react-admin';
import {
    Accordion,
    AccordionDetails,
    MenuItem,
    ListItemIcon,
    Typography,
    Box,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import BookIcon from '@mui/icons-material/Book';
import SettingsIcon from '@mui/icons-material/Settings';
import KitchenIcon from '@mui/icons-material/Kitchen';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import StoreIcon from '@mui/icons-material/Store';
import ReceiptIcon from '@mui/icons-material/Receipt';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BugReportIcon from '@mui/icons-material/BugReport';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ListIcon from '@mui/icons-material/List';
import TaskIcon from '@mui/icons-material/Task';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import EditAttributesIcon from '@mui/icons-material/EditAttributes';
import FunctionsIcon from '@mui/icons-material/Functions';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CollectionsIcon from '@mui/icons-material/Collections';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import ConstructionIcon from '@mui/icons-material/Construction';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';


import { getRoles } from './bluehillAuthDao';

import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
    closed: {
        transform: "rotate(180deg)",
    }
}));

const Header = ({ title, expanded, handleChange, icon }) => {
    const classes = useStyle();

    return (
        <MenuItem dense={false} onClick={handleChange}>
            <ListItemIcon sx={{ minWidth: 5 }}>
                {icon}
            </ListItemIcon>
            <Box sx={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}>
                <Typography variant="inherit" color="textSecondary">
                    {title}
                </Typography>
                <KeyboardArrowDownIcon
                    className={expanded ? classes.closed : ""}
                    sx={{ transition: (theme) =>
                            theme.transitions.create('transform', {
                                duration: theme.transitions.duration.shortest
                        })}}/>
            </Box>
        </MenuItem>
    );
}

const MenuContainer = ({ children, title, icon = <StoreIcon /> }) => {
    const [expanded, setExpanded] = useState(true);

    const handleChange = () => setExpanded(prev => !prev);

    return (
        <Accordion expanded={expanded}>
            <Header title={title} expanded={expanded} handleChange={handleChange} icon={icon}/>
            {children}
        </Accordion>
    );
}

export const BluehillMenu = (props) => {

    const roles = getRoles();
    return(
        <aside className="main-menu">
            {roles?.includes('menu-editor')
                ? (
                    <MenuContainer title="Store & Menu" icon={<HomeIcon />}>
                        <AccordionDetails>
                            <Menu className="sub-menu">
                                <MenuItemLink to="/stores" primaryText="Stores" leftIcon={<StoreIcon />}/>
                                <MenuItemLink to="/store_supply_items" primaryText="Store Supply" leftIcon={<KitchenIcon />}/>
                                <MenuItemLink to="/supply_items_metadata" primaryText="Supply Metadata" leftIcon={<KitchenIcon />}/>
                                <MenuItemLink to="/product_metadata" primaryText="Product Metadata" leftIcon={<LocalCafeIcon/>}/>
                                <MenuItemLink to="/product_attributes_metadata" primaryText="Attribute Metadata" leftIcon={<ListIcon/>}/>
                                <MenuItemLink to="/product_options_metadata" primaryText="Options Metadata" leftIcon={<EditAttributesIcon/>}/>
                                <MenuItemLink to="/product_groups" primaryText="Product Group" leftIcon={<CollectionsIcon/>}/>
                                <MenuItemLink to="/store_products" primaryText="Store Menu" leftIcon={<MenuBookIcon />}/>
                            </Menu>
                        </AccordionDetails>
                    </MenuContainer>
                )
                : null
            }
            <MenuContainer title="Order History" icon={<ReceiptIcon />}>
                <AccordionDetails>
                    <Menu className="sub-menu">
                        <MenuItemLink to="/order_item_records" primaryText="Order Item History" leftIcon={<LocalCafeIcon />} />
                        <MenuItemLink to="/orders" primaryText="Order History" leftIcon={<ReceiptIcon />} />
                    </Menu>
                </AccordionDetails>
            </MenuContainer>
            {roles?.includes('account-editor')
                ? (
                    <MenuContainer title="Account" icon={<PersonIcon />}>
                        <AccordionDetails>
                            <Menu className="sub-menu">
                                <MenuItemLink to="/customers" primaryText="Customers" leftIcon={<PersonIcon />} />
                                <MenuItemLink to="/coupon_templates" primaryText="Coupon Template" leftIcon={<CardGiftcardIcon />} />
                            </Menu>
                        </AccordionDetails>
                    </MenuContainer>
                )
                : null
            }
            {roles?.includes('robot-editor')
                ? (
                    <MenuContainer title="Robot Mgmt" icon={<SmartToyIcon />}>
                        <AccordionDetails>
                            <Menu className="sub-menu">
                                <MenuItemLink to="/robots" primaryText="Robot" leftIcon={<SmartToyIcon />}/>
                                <MenuItemLink to="/robot_io_groups" primaryText="Robot IO Group" leftIcon={<CollectionsIcon />}/>
                                <MenuItemLink to="/robot_ios_metadata" primaryText="Robot IO Metadata" leftIcon={<CompareArrowsIcon />}/>
                                <MenuItemLink to="/robot_ios" primaryText="Robot IOs" leftIcon={<CompareArrowsIcon />}/>
                                <MenuItemLink to="/robot_ios_control_data" primaryText="Robot IO Control Data" leftIcon={<CompareArrowsIcon />}/>
                                <MenuItemLink to="/robot_common_ios_control_data" primaryText="Common Robot IO Control Data" leftIcon={<CompareArrowsIcon />}/>
                            </Menu>
                        </AccordionDetails>
                    </MenuContainer>
                )
                : null
            }
            {roles?.includes('robot-program-editor')
                ? (
                    <MenuContainer title="Robot Program" icon={<SmartToyIcon />}>
                        <AccordionDetails>
                            <Menu className="sub-menu">
                                <MenuItemLink to="/robot_program_keys" primaryText="Program Keys" leftIcon={<ListIcon />}/>
                                <MenuItemLink to="/robot_group_programs" primaryText="Robot Group Programs" leftIcon={<ListIcon />}/>
                                <MenuItemLink to="/robot_programs" primaryText="Robot Programs" leftIcon={<ListIcon />}/>
                                <MenuItemLink to="/product_program_key_relations" primaryText="Product Mapping" leftIcon={<ListIcon />}/>
                                <MenuItemLink to="/robot_task_group_keys" primaryText="Task Group Keys" leftIcon={<TaskIcon />}/>
                                <MenuItemLink to="/robot_task_keys" primaryText="Task Keys" leftIcon={<TaskIcon />}/>
                                <MenuItemLink to="/robot_tasks" primaryText="Tasks" leftIcon={<TaskIcon />}/>
                                <MenuItemLink to="/robot_task_variable_rules" primaryText="Task Variable Rules" leftIcon={<FunctionsIcon />}/>
                                <MenuItemLink to="/robot_actions" primaryText="Actions" leftIcon={<PrecisionManufacturingIcon />}/>
                                <MenuItemLink to="/robot_attributes" primaryText="Attributes" leftIcon={<ListIcon />}/>
                                <MenuItemLink to="/robot_objects" primaryText="Robot Objects" leftIcon={<SettingsIcon />}/>
                                <MenuItemLink to="/robot_keypoints" primaryText="Keypoints" leftIcon={<GpsFixedIcon />}/>
                                <MenuItemLink to="/robot_order_test_suites" primaryText="Robot Order Test" leftIcon={<BugReportIcon />}/>
                                <MenuItemLink to="/robot_exported_data_batches" primaryText="Export Robot Data" leftIcon={<FileDownloadIcon />}/>
                                <MenuItemLink to="/robot_imported_data_batches" primaryText="Import Robot Data" leftIcon={<FileUploadIcon />}/>
                            </Menu>
                        </AccordionDetails>
                    </MenuContainer>
                )
                : null
            }
            {roles?.includes('alert-editor')
                ? (
                    <MenuContainer title="Alerts" icon={<AddAlertIcon />}>
                        <AccordionDetails>
                            <Menu className="sub-menu">
                                <MenuItemLink to="/trouble_tickets" primaryText="Trouble Tickets" leftIcon={<BugReportIcon />}/>
                                <MenuItemLink to="/issues_metadata" primaryText="Module Issue Metadata" leftIcon={<SmartToyIcon />}/>
                            </Menu>
                        </AccordionDetails>
                    </MenuContainer>
                )
                : null
            }
            {roles?.includes('supply-editor')
                ? (
                    <MenuContainer title="Supply">
                        <AccordionDetails>
                            <Menu className="sub-menu">
                                <MenuItemLink to="/store_supply_items" primaryText="Store Supply" leftIcon={<KitchenIcon />}/>
                            </Menu>
                        </AccordionDetails>
                    </MenuContainer>
    
                )
                : null
            }
            <MenuContainer title="Tool" icon={<ConstructionIcon />}>
                <AccordionDetails>
                    <Menu className="sub-menu">
                        <MenuItemLink to="/pages" primaryText="Tutorial" leftIcon={<BookIcon />}/>
                        <MenuItemLink to="/operation_history" primaryText="Operation History" leftIcon={<HistoryIcon />} />
                    </Menu>
                </AccordionDetails>
            </MenuContainer>
        </aside>
    );
};
