import React, { useEffect, useState } from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  DateField,
  SelectInput,
  useDataProvider,
  useList,
  ListContextProvider,
  ReferenceInput,
  ReferenceField,
  ReferenceArrayInput,
  CheckboxGroupInput,
  ReferenceManyField,
  Labeled,
  ShowButton,
  useRecordContext,
  useInput,
  required,
  useListContext,
} from "react-admin";
import { useFormContext, useWatch } from 'react-hook-form';

import AttributeOptionsField from './AttributeOptionsField';

const productMetadataRenderer = productMetadata => productMetadata.productName;
const CustomEmpty = () => <div> 
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    No Robot Order Test Case Yet.
    </div>;
function TestCaseList({ id, ...props }) {
    return (
        <ReferenceManyField
          reference="robot_order_test_cases"
          filter={{ id }}
          target="testSuiteId">
            <Datagrid empty={<CustomEmpty/>} bulkActionButtons={false} >
              <TextField source="testCaseId" />
              <TextField source="robotId" />
              <ReferenceField label="Product Name" source="productId" reference="product_metadata" link={false}>
                <TextField source="productName" />
              </ReferenceField>
              <AttributeOptionsField source="attributeOptions" label="Attribute Options"/>
              <TextField source="status" />
              <TextField source="error" />
              <ShowButton resource="robot_order_test_case"/>
            </Datagrid>
        </ReferenceManyField>
    );
}

const RobotOrderTestStatusField = () => {
    const record = useRecordContext();
    const colorOptions = {
        FAILED: 'red',
        SUCCESS: 'green',
      };
    return <span style={{ color: colorOptions[record?.status] }}>{record && record.status}</span>
};

export const RobotOrderTestList = props => (
    <List {...props} pagination={false} sort={{ field: 'startTime', order: 'DESC' }}>
        <Datagrid rowClick="expand" expand={TestCaseList} bulkActionButtons={false} >
            <TextField source="testSuiteId" />
            <TextField source="operator.name"/>
            <DateField options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',}} source="startTime"/>
            <DateField options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',}} source="endTime"/>
            <RobotOrderTestStatusField source="status"/>
        </Datagrid>
    </List>
);

const MyDatagrid = (props) => {
    const { selectedIds, onSelect } = useListContext();
    const storeId = useWatch({ name: 'storeId' });
    const { setValue } = useFormContext();
    useEffect(() => {
        setValue('productIds', selectedIds, { shouldDirty: true });
    }, [selectedIds, setValue]);

    useEffect(() => {
        onSelect([]);
    }, [storeId, onSelect]);
    return (
        <Datagrid bulkActionButtons={<div/>} sx={{ marginTop: 4 }} ref={props.innerRef}>
            <TextField source="distinctProductId" label="Distinct Id" />
            <TextField source="id" label="Product Id" />
            <TextField source="productName" label="Product Name"/>
        </Datagrid>
    );
}

const ProductInputContent = (props) => {
    const { data } = props;
    const listContext = useList({ data });
    return (
        <ListContextProvider value={listContext}>
            <MyDatagrid {...props}/>
        </ListContextProvider>
    );
}

const ProductInput = (props) => {
    const storeId = useWatch({ name: 'storeId' });
    const {
        field,
    } = useInput(props);
    const { ref, ...inputFields } = field;
    const [data, setData] = useState([]);
    const dataProvider = useDataProvider();
    useEffect(() => {
        if (storeId !== undefined) {
            dataProvider.getList("products", {
                pagination: {},
                sort: {},
                filter: {
                    storeId,
                },
            }).then(({ data }) => {
                if (data) {
                    setData(data.filter(e => e.supportedByRobot).map(e => ({
                        ...e,
                        id: e?.distinctProductId,
                        productId: e?.id,
                    })));
                }
            }).catch(error => {
                console.log('error', error);
            });
        }
    }, [storeId, dataProvider]);
    return (
        <ProductInputContent {...inputFields} innerRef={field.ref} data={data}/>
    );
}

const RobotInput = () => {
    const storeId = useWatch({ name: 'storeId' });
    return (
        <ReferenceArrayInput source="robotIds" label="Robot" reference="robots" filter={{ storeId }} sort={{ field: 'id', order: 'ASC' }}>
            <CheckboxGroupInput optionValue='id' optionText="robotId" />
        </ReferenceArrayInput>
    );
}

export const RobotOrderTestCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput sort={{ field:'storeId', order:'ASC' }}
                source="storeId" reference="stores"
                label="Store"
                perPage={30}
                alwaysOn>
                <SelectInput
                    optionText="storeName"
                    defaultValue={0}
                    validate={[required()]}
                    sx={{ minWidth:'120px' }}
                />
            </ReferenceInput>
            <RobotInput />
            <Labeled label="Product" fullWidth>
                <ProductInput source="productIds"/>
            </Labeled>
        </SimpleForm>
    </Create>
);
