import React, { useEffect } from 'react';
import { useListContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { Button, Toolbar, Select, FormControl, MenuItem, InputLabel, Box } from '@mui/material';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';

const addPagination = (filterValues, paginationFilters) => {
    var results = Object.assign({}, filterValues);
    delete results['pagination.scanForward'];
    delete results['pagination.firstItem'];
    delete results['pagination.lastItem'];
    return Object.assign(results, paginationFilters);
}

export const BluehillPagination = ({ showButtonText = true, className }) => {
    const { hasPreviousPage, hasNextPage, filterValues, setFilters, perPage, setPerPage, } = useListContext();

    const { 
        'pagination.lastItem': paginationLastItem,
        'pagination.firstItem': paginationFirstItem,
        'pagination.scanForward': paginationScanForward,
        ...values
    } = filterValues;

    const pagination = useSelector(state => state.pagination);
    useEffect(() => {
        if (paginationLastItem && paginationScanForward) {
            setFilters(addPagination(filterValues, { "pagination.scanForward": true }))
        }
    }, [JSON.stringify(values)])


    return (
        <Toolbar className={className}>
            <Button color="primary" key="firstPage" onClick={() => setFilters(addPagination(filterValues, { "pagination.scanForward": true }))} disabled={!hasPreviousPage}>
                <FirstPage />
                {showButtonText ? 'First Page' : null}
            </Button>
            <Button color="primary" key="prev" onClick={() => setFilters(addPagination(filterValues, {"pagination.firstItem": pagination?.firstItem, "pagination.scanForward": false}))} disabled={!hasPreviousPage}>
                <ChevronLeft />
                {showButtonText ? 'Prev' : null}
            </Button>
            <Button color="primary" key="next" onClick={() => setFilters(addPagination(filterValues, {"pagination.lastItem": pagination?.lastItem, "pagination.scanForward": true}))} disabled={!hasNextPage}>
                {showButtonText ? 'Next' : null}
                <ChevronRight />
            </Button>
            <Button color="primary" key="lastPage" onClick={() => setFilters(addPagination(filterValues, { "pagination.scanForward": false }))} disabled={!hasNextPage}>
                {showButtonText ? 'Last Page' : null}
                <LastPage />
            </Button>
            {showButtonText ? <InputLabel>Page Size</InputLabel> : null}
            <Box ml={2}>
                <FormControl variant="standard">
                    <Select
                        value={perPage}
                        onChange={({ target: { value } }) => setPerPage(value)}>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={500}>500</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Toolbar>
    );
};