import * as React from "react";
import {
    Datagrid,
    TextField,
    useRecordContext,
} from 'react-admin';
import { BaseList } from './components/BaseList';


const DurationField = ({ source }) => {
    const record = useRecordContext();
    const milliseconds = record[source];
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const days = milliseconds ? Math.floor(milliseconds / millisecondsInADay) : 0;
    return (
        <span> {days} days</span>
    );
};

export const CouponTemplateList = props => (
    <BaseList {...props} syncWithLocation pagination={false} >
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="description" />
            <TextField source="title"/>
            <DurationField source="validDuration" />
        </Datagrid>
    </BaseList>
);