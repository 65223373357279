import React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    EditButton,
    ShowButton,
    Show,
    SimpleShowLayout,
    Edit,
    DeleteButton,
    Create,
    SimpleForm,
    BooleanInput,
    NumberInput,
    SelectInput,
    ReferenceField,
    ReferenceInput,
    Labeled,
    required,
} from 'react-admin';
import { ExpandableContent } from "./components/ExpandableContent";

export const OptionMetadataList = props => (
    <List {...props} pagination={false} sort={{ field: "attributeId,sequenceNumber", order: "ASC,ASC" }}>
        <Datagrid bulkActionButtons={false} >
            <TextField label="Option Id" source="optionId" />
            <ReferenceField label="Attribute Name" source="attributeId" reference="product_attributes_metadata" >
                <TextField source="attributeName" />
            </ReferenceField>
            <TextField label="Option Name" source="optionName" />
            <TextField label="Short Description" source="shortDescription" />
            <TextField label="Sequence Number" source="sequenceNumber" />
            <BooleanField label="Default Option" source="defaultOption" />
            <TextField label="Option Key" source="optionKey" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);


export const OptionMetadataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Option Id" source="optionId" />
            <TextField label="Attribute Id" source="attributeId" />
            <ReferenceField label="Attribute name" source="attributeId" reference="product_attributes_metadata" >
                <TextField source="attributeName" />
            </ReferenceField>
            <TextField label="Option name" source="optionName" />
            <TextField label="Short description" source="shortDescription" />
            <TextField label="Sequence number" source="sequenceNumber" />
            <BooleanField label="Default option" source="defaultOption" />
            <TextField label="Option key" source="optionKey" />
        </SimpleShowLayout>
    </Show>
);


export const OptionMetadataEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Labeled label="Option Id">
                <TextField label="Option Id" source="optionId" />
            </Labeled>
            <Labeled label="Attribute Id" >
            <TextField source="attributeId" />
            </Labeled>
            <Labeled label="Attribute name">
                <ReferenceField label="Attribute name" source="attributeId" reference="product_attributes_metadata" >
                    <TextField source="attributeName" />
                </ReferenceField>
            </Labeled>
            <TextInput label="Option name" source="optionName" />
            <TextInput label="Short description" source="shortDescription" />
            <NumberInput label="Sequence number" source="sequenceNumber" />
            <BooleanInput label="Default option" source="defaultOption" />
            <Labeled label="Option key">
                <TextField label="Option key" source="optionKey" />
            </Labeled>
        </SimpleForm>
    </Edit>
);

export const OptionMetadataCreate = props => {
    const attributeRenderer = attribute => attribute.attributeName;
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput sort={{ field: '', order: 'ASC' }} 
                    label="Attribute" source="attributeId" reference="product_attributes_metadata">
                    <SelectInput optionText={attributeRenderer} validate={[required()]}/>
                </ReferenceInput>
                <TextInput label="Option name" source="optionName" validate={[required()]} />
                <TextInput label="Short description" source="shortDescription" />
                <NumberInput label="Sequence number" source="sequenceNumber" defaultValue={100} helperText="Display position, the smaller the number, the higher the sorting" />
                <BooleanInput label="Default option" source="defaultOption" />
                <ExpandableContent buttonText="Developer Use Only">
                    <TextInput label="Option key" source="optionKey" fullWidth />
                </ExpandableContent>
            </SimpleForm>
        </Create>
    );
};