import React from "react";
import { Select, MenuItem, TextField } from '@mui/material';

const DisabledReasonInput = ({ reasonType, setReasonType, reasonDescription, setReasonDescription }) => {
  const handleReasonTypeChange = (event) => {
    setReasonType(event.target.value);
  };

  const handleReasonDescriptionChange = (event) => {
    setReasonDescription(event.target.value);
  };
  return (<>
    <span>
      <span>Reason for disabling: </span>
      <Select
        labelId="select-label"
        value={reasonType}
        onChange={handleReasonTypeChange}
      >
        <MenuItem value={"OUT_OF_STOCK"}>Out of stock</MenuItem>
        <MenuItem value={"HARDWARE_ISSUE"}>Hardware issue</MenuItem>
        <MenuItem value={"SOFTWARE_ISSUE"}>Software issue</MenuItem>
        <MenuItem value={"CUSTOM"}>Other/unknown</MenuItem>
      </Select>
    </span>
    <TextField
      label="Add more details (optional)"
      value={reasonDescription}
      onChange={handleReasonDescriptionChange}
      fullWidth
      multiline
    />
  </>)
}

export default DisabledReasonInput;
