import React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    EditButton,
    ShowButton,
    SaveButton,
    Show,
    SimpleShowLayout,
    Edit,
    DeleteButton,
    Create,
    SimpleForm,
    NumberInput,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    NumberField,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    TabbedForm,
    ImageField,
    ImageInput,
    Toolbar,
    RadioButtonGroupInput,
    useDataProvider,
    useRedirect,
    useNotify,
    useEditController,
    required,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { ImageUpload } from "./components/ImageUpload";


export const ProductGroupsList = props => (
    <List {...props} pagination={false}  sort={{ field: "groupKey", order: "ASC" }}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="groupKey" />
            <TextField source="groupName" />
            <TextField source="groupType" />
            <NumberField source="sequenceNumber" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export const ProductGroupsShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="groupKey" />
            <TextField source="groupName" />
            <TextField source="groupType" />
            <NumberField source="sequenceNumber" />
            <ReferenceArrayField label="Products" source="distinctProductIds" 
                reference="product_metadata" fullWidth sort={{ field: "productName", order: "ASC" }}>
                <SingleFieldList>
                    <ChipField source="productName" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ImageField source="imageUrl" label="Image" />
            <ImageField source="imageThumbnailUrl" label="Image Thumbnail"/>
        </SimpleShowLayout>
    </Show>
);

export const ProductGroupsEdit = props => {
    const controllerProps = useEditController(props);
    const [uploadMode, setUploadMode] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();
    const location = useLocation();
    const { save } = controllerProps;

    const onSave = async (data) => {
        const { distinctProductIds, ...entity } = data;
        try {
            if (location.pathname.split('/').pop().includes('products')) {
                await dataProvider.update('product_groups_relation', {
                    id: data.id,
                    data: {
                        distinctProductIds: data.distinctProductIds
                    }
                });
                redirect('list', '/product_groups');
                notify(`Element updated!`);
            } else {
                save(entity);
            }
        } catch (error) {
            notify(`Update failed!`);
        }
    }
    return (
        <Edit {...props}>
            <TabbedForm save={onSave} toolbar={<CustomToolbar imageUploaded={imageUploaded} uploadMode={uploadMode} deleteButton={true} />}
            sanitizeEmptyValues
            >
                <TabbedForm.Tab label="Basic Info">
                    <TextField source="groupKey" />
                    <TextInput source="groupName" />
                    <TextInput source="groupType" />
                    <NumberInput source="sequenceNumber" />
                    <ImageField source="imageUrl" label="Current image" />
                    <ImageField source="imageThumbnailUrl" label="Current image thumbnail" />
                    <ImageUpload imageUploaded={imageUploaded} uploadMode={uploadMode}
                        setImageUploaded={setImageUploaded} setUploadMode={setUploadMode}
                        imageSource="productGroupImageSource" imageFile="groupImageFile"
                        primaryImageUrl="imageUrl" primaryImageThumbnailUrl="imageThumbnailUrl"></ImageUpload>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Products" path="products">
                    <ReferenceArrayInput label="Products" source="distinctProductIds" validate={[required()]}
                        reference="product_metadata" fullWidth sort={{ field: "productName", order: "ASC" }}>
                        <AutocompleteArrayInput optionText="productName" />
                    </ReferenceArrayInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
}

export const ProductGroupsCreate = props => {
    const [uploadMode, setUploadMode] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState(false);

    return (<Create {...props}>
            <SimpleForm toolbar={<CustomToolbar imageUploaded={imageUploaded} uploadMode={uploadMode} deleteButton={false} />}
            sanitizeEmptyValues
            >
                <TextInput source="groupKey" required />
                <TextInput source="groupName" required />
                <TextInput source="groupType" required />
                <NumberInput source="sequenceNumber" required />
                <ImageField source="imageUrl" label="Current image" />
                <ImageField source="imageThumbnailUrl" label="Current image thumbnail" />
                <ImageUpload imageUploaded={imageUploaded} uploadMode={uploadMode}
                    setImageUploaded={setImageUploaded} setUploadMode={setUploadMode}
                    imageSource="productGroupImageSource" imageFile="groupImageFile"
                    primaryImageUrl="imageUrl" primaryImageThumbnailUrl="imageThumbnailUrl"></ImageUpload>
            </SimpleForm>
        </Create>);
}

export const CustomToolbar = ({ uploadMode, imageUploaded, deleteButton, ...props }) => (
    <Toolbar {...props} style={{ "justifyContent": "space-between" }}>
        <SaveButton disabled={(!uploadMode && imageUploaded)} />
        {deleteButton && <DeleteButton />}
    </Toolbar>
);
