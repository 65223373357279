import React from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { useRecordContext } from 'react-admin';


const AttributeOptionsField = (props) => {

  const record = useRecordContext(props);

  const { attributeOptions } = record;

  let options = [];

  // Check if attributeOptions is already a parsed object
  if (typeof attributeOptions === 'object') {
    options = attributeOptions;
  } else {
    // Attempt to parse the attributeOptions string
    try {
      options = JSON.parse(attributeOptions);
    } catch (error) {
      console.error('Error parsing attributeOptions:', error);
    }
  }

  return (
      <div style={{ maxWidth: '500px' }}>
        
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Attribute</TableCell>
              <TableCell>Option</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((option, index) => (
              <TableRow key={index}>
                <TableCell>{option.attributeName}</TableCell>
                <TableCell>{option.optionName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );

};
export default AttributeOptionsField;