import React from "react";

import { useState } from "react";
import { Collapse, Button } from '@mui/material';
import ExpandMore from "@mui/icons-material/ExpandMore";

export const ExpandableContent = ({ buttonText, children, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <>
            <Button
                fullWidth
                onClick={handleExpandClick}
                variant="outlined"
            >
                {<ExpandMore />}
                {buttonText}
            </Button>
            <Collapse in={isExpanded} {...props}>{children}</Collapse>
        </>
    );
};