import * as React from "react";

import {
    List,
    Datagrid,
    TextField,
    TextInput,
    BooleanField,
    EditButton,
    ShowButton,
    Show,
    SimpleShowLayout,
    Edit,
    Create,
    SimpleForm,
    BooleanInput,
    required,
    minValue,
    maxValue,
    NumberField,
    NumberInput,
    SelectInput,
    Button,
    AutocompleteInput,
    CloneButton,
} from 'react-admin';
import { useWatch, useFormContext } from 'react-hook-form';
import { ExpandableContent } from "./components/ExpandableContent";

const severityChoices = [
    { id: 1, name: '1 (high severity)' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5 (low severity)' },
];

const moduleIdChoices = [
    { id: 'robot', name: 'robot' },
    { id: 'computer', name: 'computer' },
    { id: 'bluehillCloudNode', name: 'bluehillCloudNode' },
    { id: 'bluehillControlNode', name: 'bluehillControlNode' },
    { id: 'bluehillGs3Node', name: 'bluehillGs3Node' },
    { id: 'bluehillMilkWeightNode', name: 'bluehillMilkWeightNode' },
    { id: 'bluehillSyrupWeightNode', name: 'bluehillSyrupWeightNode' },
    { id: 'bluehillIceWeightNode', name: 'bluehillIceWeightNode' },
    { id: 'arduinoScaleMilkNode', name: 'arduinoScaleMilkNode' },
    { id: 'arduinoScaleSyrupNode', name: 'arduinoScaleSyrupNode' },
    { id: 'arduinoScaleIceNode', name: 'arduinoScaleIceNode' },
    { id: 'arduinoFridgeNode', name: 'arduinoFridgeNode' },
    { id: 'arduinoScaleMilkFridgeNode', name: 'arduinoScaleMilkFridgeNode' },
    { id: 'arduinoScaleOatFridgeNode', name: 'arduinoScaleOatFridgeNode' },
    { id: 'urDriverNode', name: 'urDriverNode' },
    { id: 'cameraWrist', name: 'cameraWrist' },
    { id: 'humiditySensorNode', name: 'humiditySensorNode' },
    { id: 'arduinoCupDispenserNode.cupDispenser.paperCup.8oz', name: 'arduinoCupDispenserNode.cupDispenser.paperCup.8oz' },
    { id: 'arduinoCupDispenserNode.cupDispenser.paperCup.10oz', name: 'arduinoCupDispenserNode.cupDispenser.paperCup.10oz' },
    { id: 'arduinoCupDispenserNode.cupDispenser.paperCup.12oz', name: 'arduinoCupDispenserNode.cupDispenser.paperCup.12oz' },
    { id: 'arduinoCupDispenserNode.cupDispenser.plasticCup.12oz', name: 'arduinoCupDispenserNode.cupDispenser.plasticCup.12oz' },
    { id: 'arduinoCupDispenserNode.cupDispenser.plasticCup.16oz', name: 'arduinoCupDispenserNode.cupDispenser.plasticCup.16oz' },
    { id: 'grinderKnobServoNode', name: 'grinderKnobServoNode' },
];

const issueTypeChoices = [
    { id: 'MAINTENANCE', name: 'MAINTENANCE' },
    { id: 'TECHNICAL_ISSUE', name: 'TECHNICAL_ISSUE' },
    { id: 'INFORMATIONAL', name: 'INFORMATIONAL' },
    { id: 'TEST', name: 'TEST' },
];

const generateRobotVoiceMessage = (description, instruction) => {
    return [description, instruction].filter(Boolean).join(' ');
};

const RobotVoiceMessageInput = (props) => {
    const { ...rest } = props;
    const { setValue } = useFormContext();
    const description = useWatch({ name: 'description' });
    const instruction = useWatch({ name: 'instruction' });

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextInput initialValue={generateRobotVoiceMessage(description, instruction)}
                source="robotVoiceMessage" defaultValue={""} {...rest} />
            <Button color="primary" onClick={() => 
                setValue('robotVoiceMessage', generateRobotVoiceMessage(description, instruction))} 
                label="Reset to default" style={{ width: '200px' }} />
        </div>
    );
};

const VariableReferenceLink = (props) => 
<div {...props}>
    <a href="https://www.notion.so/bluehill/a028ce12da2647f8a63d3ce3d1835696"
        target="_blank" rel="noopener noreferrer">
        Variable reference (Notion)
    </a>
</div>

export const IssuesMetadataList = props => (
    <List {...props} pagination={false} sort={{ field: 'id', order: 'ASC' }}>
        <Datagrid bulkActionButtons={false} >
            <TextField label="Module" source="moduleId" style={{ wordBreak: 'break-word' }} />
            <TextField label="Issue Key" source="issueKey" style={{ wordBreak: 'break-word' }} />
            <TextField label="Issue Type" source="issueType" />
            <NumberField label="Severity" source="severity" />
            <TextField label="Description" source="description" />
            <ShowButton />
            <EditButton />
            <CloneButton />
        </Datagrid>
    </List>
);

export const IssuesMetadataShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Module" source="moduleId" />
            <TextField label="Issue Key" source="issueKey" />
            <NumberField label="Severity (1-5)" source="severity" />
            <TextField label="Issue Type" source="issueType" />
            <TextField label="Issue Type (V2)" source="issueTypeV2" />
            <TextField label="Description" source="description" />
            <TextField label="Instruction" source="instruction" />
            <TextField label="Robot Voice Message (for robot reported issues only)" source="robotVoiceMessage" />
            <NumberField label="Allowed Max Attempts" source="allowedMaxAttempts" />
            <NumberField label="Time To Escalate (min)" source="timeToEscalate" transform={value => value / 60_000} />
            <NumberField label="Minimum Notification Interval (min)" source="minimumNotificationInterval" transform={value => value / 60_000} />
            <BooleanField label="Cross-robot Alarm" source="crossRobotAlarm" />
            <TextField label="Instruction Pattern (legacy)" source="instructionPattern" />
            <BooleanField label="Instruction Spoken (legacy)" source="instructionSpoken" />
        </SimpleShowLayout>
    </Show>
);

const FormCommonFields = (props) => (
    <>
        <SelectInput label="Issue Type" source="issueType" choices={issueTypeChoices} validate={[required()]} />
        <SelectInput label="Severity (1-5)" source="severity" choices={severityChoices} validate={[required(), minValue(1), maxValue(5)]} />
        <VariableReferenceLink />
        <TextInput label="Description" source="description" multiline fullWidth validate={[required()]} />
        <TextInput label="Instruction" source="instruction" multiline fullWidth 
            defaultValue={""} validate={[required()]} />
        <RobotVoiceMessageInput label={"Robot Voice Message (For robot reported issues only. Leave empty for no message.)"} 
            source="robotVoiceMessage" multiline defaultValue={""} fullWidth />
        <NumberInput label="Allowed Max Attempts" source="allowedMaxAttempts" />
        <NumberInput
            label="Time To Escalate (min)"
            source="timeToEscalate"
            defaultValue={4 * 60 * 1000}
            validate={[required()]}
            format={value => value / 60_000}
            parse={value => value * 60_000}
        />
        <NumberInput
            label="Minimum Notification Interval (min)"
            source="minimumNotificationInterval"
            defaultValue={60 * 1000}
            validate={[required()]}
            format={value => value / 60_000}
            parse={value => value * 60_000}
        />
        <BooleanInput label="Cross-robot Alarm" source="crossRobotAlarm" defaultValue={false} validate={[required()]} />
        <ExpandableContent buttonText={"Developer Use Only"}> 
            <SelectInput label="Issue Type V2" source="issueTypeV2" fullWidth choices={issueTypeChoices} />
            <TextInput label="Instruction Pattern (legacy)" source="instructionPattern" fullWidth />
            <BooleanInput label="Instruction Spoken (legacy)" source="instructionSpoken" fullWidth />
        </ExpandableContent>
    </>
);

export const IssuesMetadataCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <AutocompleteInput label="Module" source="moduleId" choices={moduleIdChoices} validate={[required()]} />
            <TextInput label="Issue Key" source="issueKey" validate={[required()]} />
            <FormCommonFields />
        </SimpleForm>
    </Create>
);

export const IssuesMetadataEdit = props => (
    <Edit {...props}>
        <SimpleForm redirect="list">
            <TextField label="Module" source="moduleId" />
            <TextField label="Issue Key" source="issueKey" />
            <FormCommonFields />
        </SimpleForm>
    </Edit>
);