import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    TextField,
    RichTextField,
    DateField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    SimpleShowLayout,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { BluehillPagination } from './bluehillPagination';

const pageDefaultValue = () => ({ categoryKey: 'tutorial.document' });

export const PageList = props => (
    <List {...props}  perPage={50} sort={{field:'', order:''}}
        pagination={<BluehillPagination />}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="title" />
            <DateField source="updatedTime" showTime />
            <EditButton />
        </Datagrid>
    </List>
);

export const PageShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="title" />
            <RichTextField source="content" />
        </SimpleShowLayout>
    </Show>
);

export const PageEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="pageId" />
            <TextField source="categoryKey" />
            <TextInput source="title" />
            <RichTextInput source="content" />
        </SimpleForm>
    </Edit>
);
export const PageCreate = props => (
    <Create {...props}>
        <SimpleForm defaultValues={pageDefaultValue}>
            <TextInput source="title" />
            <RichTextInput source="content" />
        </SimpleForm>
    </Create>
);