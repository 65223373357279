import { createStore, combineReducers } from 'redux';

const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";

const pagination = (previousState = {}, { type, payload }) => {
  if (type === GET_LIST_SUCCESS && payload.pagination) {
      return payload.pagination;
  }
  return previousState;
}

const getList = (data) => {
  return { type: GET_LIST_SUCCESS, payload: data };
}

const store = createStore(combineReducers({ pagination }));

export { getList, pagination, store };
