import React, { useCallback, useMemo, useState } from 'react';
import {
    TopToolbar,
    List,
    Show,
    Datagrid,
    BooleanField,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    DeleteButton,
    EditButton,
    ShowButton,
    BulkUpdateButton,
    Edit,
    Create,
    CreateButton,
    useCreateController,
    SimpleForm,
    BooleanInput,
    NullableBooleanInput,
    TextInput,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    FormDataConsumer,
    SimpleShowLayout,
    useRecordContext,
    CloneButton,
    SaveButton,
    Toolbar,
    required,
    Labeled,
    useListContext,
    BulkUpdateWithConfirmButton,
    DateField,
    FunctionField,
    Link,
} from 'react-admin';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { Link } from '@mui/material';

import { BluehillPagination } from './bluehillPagination';

import SortableIterator from './components/SortableIterator';
import AutocompleteInput from './components/BaseAutocompleteInput';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Export Configuration Data" />
    </TopToolbar>
);

const ExportButton = () => {
    return (
        <SaveButton type="button" label="Export" />
    );
};

const RobotExportedDataBatchCreateToolbar = () => (
    <Toolbar>
        <ExportButton />
    </Toolbar>
);

export const RobotExportedDataBatchList = props => (
    <List {...props} 
        perPage={50} sort={{ field: '', order: '' }}
        pagination={<BluehillPagination />}
        actions={<ListActions />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="batchId" />
            <TextField source="configuration" />
            <TextField source="status" />
            <DateField source="createdTime" showTime />
            <DateField source="updatedTime" showTime />
            <ShowButton />
        </Datagrid>
    </List>
);

function ExportField() {
    const record = useRecordContext();
    const onDownload = useCallback((ev) => {
        ev.preventDefault();
        fetch(record.exportedFileUrl, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = record.exportedFileUrl.split('/').pop();
                a.click();
            });
    }, [record]);
    return record.exportedFileUrl ? <Link to={record.exportedFileUrl} onClick={onDownload}>{record.exportedFileUrl}</Link> : null;
}

export const RobotExportedDataBatchShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="batchId" />
                <TextField source="configuration" />
                <TextField source="status" />
                <ExportField source="exportedFileUrl" />
                <DateField source="createdTime" showTime />
                <DateField source="updatedTime" showTime />
            </SimpleShowLayout>
        </Show>
    );
};

export const RobotExportedDataBatchCreate = props => {
    const { record } = useCreateController(props);
    return (<Create {...props}>
        <SimpleForm toolbar={<RobotExportedDataBatchCreateToolbar />}>
            <TextInput source="configuration" />
        </SimpleForm>
    </Create>
)};
