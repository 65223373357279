import React, { useMemo, useState } from 'react';
import {
    List,
    Show,
    Datagrid,
    BooleanField,
    TextField,
    ReferenceField,
    ReferenceArrayField,
    DeleteButton,
    EditButton,
    ShowButton,
    BulkUpdateButton,
    Edit,
    Create,
    useCreateController,
    SimpleForm,
    BooleanInput,
    NullableBooleanInput,
    TextInput,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    FormDataConsumer,
    SimpleShowLayout,
    useRecordContext,
    CloneButton,
    CreateButton,
    SaveButton,
    TopToolbar,
    Toolbar,
    required,
    Labeled,
    useListContext,
    BulkUpdateWithConfirmButton,
    DateField,
    FunctionField,
    FileInput,
    FileField,
    CreateParams,
} from 'react-admin';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { BluehillPagination } from './bluehillPagination';

import SortableIterator from './components/SortableIterator';
import AutocompleteInput from './components/BaseAutocompleteInput';

const ListActions = () => (
    <TopToolbar>
        <CreateButton label="Import Configuration Data" />
    </TopToolbar>
);

const ImportButton = () => {
    return (
        <SaveButton type="button" label="Import" />
    );
};

const RobotImportedDataBatchCreateToolbar = () => (
    <Toolbar>
        <ImportButton />
    </Toolbar>
);

export const RobotImportedDataBatchList = props => (
    <List {...props} 
        perPage={50} sort={{ field: '', order: '' }}
        pagination={<BluehillPagination />}
        actions={<ListActions />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="batchId" />
            <TextField source="configuration" />
            <TextField source="status" />
            <DateField source="createdTime" showTime />
            <DateField source="updatedTime" showTime />
        </Datagrid>
    </List>
);


export const RobotImportedDataBatchCreate = props => {
    const { record } = useCreateController(props);
    return (<Create {...props}>
        <SimpleForm toolbar={<RobotImportedDataBatchCreateToolbar />}>
            <TextInput source="configuration" />
            <FileInput source="robotConfigurationFile">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    </Create>
)};
