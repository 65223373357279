import React, { useEffect } from "react";
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    Edit,
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import { BluehillPagination } from './bluehillPagination';

const toTaskName = (taskKey) => {
    if (taskKey) {
        const taskKeyParts = taskKey.split("|");
        var result = snakeCaseToName(taskKeyParts[0]);
        if (taskKeyParts.length >= 2) {
            result += ' | ';
            result += parseTaskKeyPart1(taskKeyParts[1]);
        }
        return result;
    } else {
        return '';
    }
};

const resetTaskName = (taskKey) => {
    return toTaskName(taskKey);
};

const snakeCaseToName = (taskKeyPart0) => {
    const strs = taskKeyPart0.split("_");
    var result = '';
    for (var i = 0; i < strs.length; i++) {
        result += toUpperCamelCase(strs[i]);
        if (i < strs.length - 1) {
            result += ' ';
        }
    }
    return result;
};

const parseTaskKeyPart1 = (taskKeyPart1) => {
    const strs = taskKeyPart1.split(",");
    var result = '';
    for (var i = 0; i < strs.length; i++) {
        result += snakeCaseToName(strs[i]);
        if (i < strs.length - 1) {
            result += ',';
        }
    }
    return result;
};

const toUpperCamelCase = (str) => {
    if (str) {
        var result = '';
        if (str.length >= 1) {
            result += str.charAt(0).toUpperCase();
        }
        for (var i = 1; i < str.length; i++) {
            result += str.charAt(i).toLowerCase();
        }
        return result;
    } else {
        return '';
    }
};

const TaskNameCreateInput = props => {
    const { watch, setValue } = useFormContext();
    const taskKey = useWatch({ name: 'taskKey' });

    useEffect(() => {
        setValue('taskName', toTaskName(taskKey));
    }, [taskKey, setValue]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextInput source="taskName" defaultValue={toTaskName(taskKey)} style={{ marginRight: '15px' }} />
            <Button color="primary" onClick={() => setValue('taskName', toTaskName(watch('taskKey')))}>Reset to default name</Button>
        </div>
    );
};

const TaskNameEditInput = props => {
    const { watch, setValue } = useFormContext();
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextInput source="taskName" style={{ marginRight: '15px' }}/>
            <Button color="primary" onClick={() => setValue('taskName', toTaskName(watch('taskKey')))}>Reset to default name</Button>
        </div>
    );
};

const filters = [
    <TextInput label="Task Key" source="partialTaskKey" alwaysOn />,
];

export const TaskKeyList = props => (
    <List {...props} perPage={50} sort={{ field: '', order: '' }}
        filters={filters} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="taskKey" />
            <TextField source="taskName" />
            <TextField source="taskType" />
            <EditButton />
        </Datagrid>
    </List>
);

export const TaskKeyEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="taskKey" />
            <TextInput source="taskType" />
            <TaskNameEditInput />
        </SimpleForm>
    </Edit>
);

export const TaskKeyCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm >
                <TextInput source="taskKey" />
                <TextInput source="taskType" />
                <TaskNameCreateInput />
            </SimpleForm>
        </Create>
    );
};