import React from 'react';
import {
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  Labeled
} from "react-admin";
import { JsonField } from 'react-admin-json-view';
import AttributeOptionsField from './AttributeOptionsField';

export const RobotOrderTestCaseShow = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="testCaseId" />
                <TextField source="robotId" />
                <ReferenceField label="Product Name" source="productId" reference="product_metadata" link={false}>
                    <TextField source="productName" />
                </ReferenceField>
                <Labeled label="Attribute Options:">
                    <AttributeOptionsField source="attributeOptions" label="Attribute Options"/>
                </Labeled>
                <TextField source="status" />
                <Labeled label="Directive" >
                    <JsonField source="directive" />
                </Labeled>
                <TextField source="error" />
            </SimpleShowLayout>
        </Show>
    );
};